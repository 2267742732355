import React, { useMemo, useState } from 'react'
import { useMantineTheme } from '@mantine/core'
import Loading from '../Loading'

interface HeaderIconWrapperProps {
  selected?: boolean
  hovered: boolean
  iconDark: string
  iconLight: string
  loader?: boolean
  imageLoaderHeight?: string
}
const HeaderIconWrapper: React.FC<HeaderIconWrapperProps> = ({
  selected = false,
  hovered,
  iconDark,
  iconLight,
  loader = false,
  imageLoaderHeight = '100%',
}) => {
  const theme = useMantineTheme()
  const [isLoading, setIsLoading] = useState(true)
  const darkmode = theme.colors.background[0] === '#000000'

  const icon = useMemo(() => {
    if (hovered) {
      return darkmode ? iconDark : iconLight
    }
    if (selected) {
      return darkmode ? iconDark : iconLight
    }
    return darkmode ? iconLight : iconDark
  }, [selected, hovered, darkmode])

  const handleLoading = () => {
    if (loader) {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && loader && <Loading imageLoaderHeight={imageLoaderHeight} />}
      <img
        style={{ display: isLoading && loader ? 'none' : 'block' }}
        src={icon}
        alt={icon}
        onLoad={handleLoading}
      />
    </>
  )
}

export default HeaderIconWrapper
