import { Stack, Title, Text, createStyles, useMantineTheme } from '@mantine/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'
import RootButton from './Buttons/RootButton'
import { BodyStack } from '../styled/components'
import IconWrapper from './MediaWrappers/IconWrapper'
import homeBlackIcon from '../assets/icons/home_black.svg'
import homeWhiteIcon from '../assets/icons/home_white.svg'

const useStyles = createStyles(() => ({
  pageTile: {
    marginTop: 'calc(100px + 10vh) !important',
  },
  pageText: {
    textAlign: 'initial',
    fontWeight: 400,
    fontFamily: 'monument-grotesk-regular',
  },
  link: {
    textAlign: 'initial',
  },
}))

const NotFound: React.FC = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const { classes } = useStyles()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`)

  return (
    <BodyStack className={classes.pageTile} spacing="15px">
      <Title order={isMobile ? 3 : 2}>{t('common.notFoundTitle', 'Page Not Found')}</Title>
      <Stack spacing="30px">
        <Text
          className={classes.pageText}
          sx={{
            fontSize: isMobile ? '27px' : '34px',
          }}
        >
          {t(
            'common.notFoundMessage',
            `We're sorry, but the page you're trying to reach does not exist or is currently under construction.`
          )}
        </Text>
        <Link className={classes.link} to="/home">
          <RootButton
            leftIcon={<IconWrapper iconDark={homeBlackIcon} iconLight={homeWhiteIcon} />}
            darkMode={false}
          >
            {t('common.returnHome', 'Return Home')}
          </RootButton>
        </Link>
      </Stack>
    </BodyStack>
  )
}

export default NotFound
