import React from 'react'
import { createStyles, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import RootButton, { RootButtonProps } from './RootButton'

interface StyleParams {
  isNotDesktop: boolean
}

const useStyles = createStyles((theme, { isNotDesktop }: StyleParams) => ({
  headerButtonActive: {
    height: '48px',
    fontSize: '14px',
    padding: isNotDesktop ? '7px 22px' : '',
    borderRadius: '12px',
    backgroundColor: theme.colors.title[0],
    color: theme.colors.background[0],
    fill: theme.colors.background[0],
    borderColor: theme.colors.title[0],
  },
}))

interface HeaderButtonProps extends RootButtonProps {
  pagePath: string
  iconOnly?: boolean
}

const HeaderButton: React.FC<HeaderButtonProps> = ({ pagePath, iconOnly, ...props }) => {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const isNotDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`)
  const { classes } = useStyles({ isNotDesktop })
  const { pathname } = useLocation()
  const newProps =
    pathname === pagePath ? { className: classes.headerButtonActive, ...props } : props

  return (
    <RootButton
      transparent
      sx={{
        minWidth: iconOnly ? 'auto' : '150px',
        maxWidth: '150px',
        fontFamily: 'monument-grotesk-black',
        span: {
          margin: iconOnly ? 'auto' : '',
          width: iconOnly ? '100%' : '',
        },
      }}
      {...newProps}
      onClick={() => navigate(pagePath)}
    />
  )
}

export default HeaderButton
