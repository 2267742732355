import type { OpenloginUserInfo } from '@web3auth/openlogin-adapter/dist/types/interface'
import type { L1Provider } from '@/plugins/auth/AuthProvider/types/context'
import type { UserIdentity } from '@/utils/utils'

export enum AuthActionType {
  DisconnectInit = 'DisconnectInit',
  DisconnectWagmi = 'DisconnectWagmi',
  DisconnectStartWeb3AuthLogout = 'DisconnectStartWeb3AuthLogout',
  Disconnected = 'Disconnected',

  ConnectInit = 'ConnectInit',
  ConnectWagmi = 'ConnectWagmi',
  ConnectCancel = 'ConnectCancel',
  ConnectUserInfo = 'ConnectUserInfo',
  ConnectUserIdentity = 'ConnectUserIdentity',
  ConnectProvider = 'ConnectProvider',
  ConnectError = 'ConnectError',

  Connected = 'Connected',
}

export type AuthActionDisconnectInit = {
  type: AuthActionType.DisconnectInit
}
export type AuthActionDisconnectWagmi = {
  type: AuthActionType.DisconnectWagmi
}
export type AuthActionDisconnectStartWeb3AuthLogout = {
  type: AuthActionType.DisconnectStartWeb3AuthLogout
}
export type AuthActionDisconnected = {
  type: AuthActionType.Disconnected
}

export type AuthActionConnectInit = {
  type: AuthActionType.ConnectInit
}
export type AuthActionConnectWagmi = {
  type: AuthActionType.ConnectWagmi
}
export type AuthActionConnectCancel = {
  type: AuthActionType.ConnectCancel
}
export type AuthActionConnectUserInfo = {
  type: AuthActionType.ConnectUserInfo

  address: `0x${string}`
}
export type AuthActionConnectUserIdentity = {
  type: AuthActionType.ConnectUserIdentity

  userInfo: Partial<OpenloginUserInfo>
}
export type AuthActionConnectProvider = {
  type: AuthActionType.ConnectProvider

  userIdentity: UserIdentity
}
export type AuthActionConnectError = {
  type: AuthActionType.ConnectError

  error: any
}
export type AuthActionConnected = {
  type: AuthActionType.Connected

  provider: L1Provider
}

export type AuthAction =
  | AuthActionDisconnectInit
  | AuthActionDisconnectWagmi
  | AuthActionDisconnectStartWeb3AuthLogout
  | AuthActionDisconnected
  | AuthActionConnectInit
  | AuthActionConnectWagmi
  | AuthActionConnectCancel
  | AuthActionConnectUserInfo
  | AuthActionConnectUserIdentity
  | AuthActionConnectProvider
  | AuthActionConnectError
  | AuthActionConnected
