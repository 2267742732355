import i18next, { i18n as I18n, BackendModule, InitOptions } from 'i18next'
import ClientLanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { FC, ReactNode, useEffect } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'

const I18N_DEFAULT_NS = 'webWallet'
export const I18N_DEFAULT_LOCALE = 'en-US'

const i18nOptions = {
  fallbackLng: I18N_DEFAULT_LOCALE,
  debug: process.env.NODE_ENV === 'development',
  defaultNS: I18N_DEFAULT_NS,
  ns: [I18N_DEFAULT_NS],
  cleanCode: true,
  load: 'currentOnly',
  supportedLngs: I18N_LOCALES,
} as const satisfies InitOptions

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
const _backends = [
  resourcesToBackend(I18N_DEFAULT_LOCALE_RESOURCES),
  LocalStorageBackend,
  HttpBackend,
] as const
const backends = _backends as DeepMutable<typeof _backends>

type ExtractBackendOption<T extends BackendModule<any> | Constructor<BackendModule<any>, any>> =
  T extends BackendModule<infer Opts>
    ? Opts
    : T extends Constructor<BackendModule<infer Opts>>
      ? Opts
      : never
type ExtractBackendOptions<
  B extends (BackendModule<any> | Constructor<BackendModule<any>, any>)[],
> = {
  [K in keyof B]: ExtractBackendOption<B[K]>
} & { length: B['length'] }

interface BackendType extends Omit<ChainedBackendOptions, 'backends' | 'backendOptions'> {
  backends: typeof backends
  backendOptions: ExtractBackendOptions<typeof backends>
}

// Closure that returns a singleton i18next instance,
// instantiated lazily.
export const getI18nInstance = (() => {
  let i18nInstance: I18n

  // eslint-disable-next-line @typescript-eslint/naming-convention
  return function _getI18nInstance(): I18n {
    if (i18nInstance) return i18nInstance

    i18nInstance = i18next.createInstance() as I18n

    i18nInstance
      .use(initReactI18next)
      .use(ClientLanguageDetector)
      .use(ChainedBackend)
      .init({
        ...i18nOptions,
        backend: {
          backends,
          backendOptions: [
            {},
            {
              // Options for LocalStorageBackend
              prefix: 'i18next_res_',
              expirationTime: 60 * 1000, // 1 minute
              store: window.localStorage,
            },
            {
              // Options for HttpBackend
              loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
          ],
          cacheHitMode: 'refreshAndUpdateStore',
          refreshExpirationTime: 60 * 1000, // 1 minute
        } satisfies BackendType,
      })

    return i18nInstance
  }
})()

// Provides the context for the i18next instance and a
// promise to the available languages, so we can suspend
// when we need them, but start fetching them on app mount
export const I18NextProvider: FC<{ children?: ReactNode | undefined }> = ({ children }) => {
  const i18n = getI18nInstance()

  // This context depends on asynchronous data, so I'm using a promise
  // as its value so that the data can be updated asynchronously
  // and the components that need it can use Suspend or a useEffect
  // to wait for it. This unblocks the rendering of its children
  // and prevents them from rendering with stale data.
  useEffect(() => {
    const setHtmlLang = (lng: string) => {
      document.querySelector('html')?.setAttribute('lang', lng)
    }

    i18n.on('languageChanged', setHtmlLang)
    setHtmlLang(i18n.language)

    return () => {
      i18n.off('languageChanged', setHtmlLang)
    }
  }, [i18n])

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
