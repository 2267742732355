import { Center } from '@mantine/core'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/plugins/auth'
import {
  getAuthedRedirectPath,
  getUnauthedRedirectPath,
} from '@/plugins/auth/RouteRedirectors/utils'
import Loading from '@/components/Loading'

const RootRedirector: React.FC = () => {
  const navigate = useNavigate()
  const { status } = useAuth()

  useEffect(() => {
    if (status === 'connected') {
      navigate(getAuthedRedirectPath({ restoreOldRoute: window.location.pathname !== '/' }), {
        replace: true,
      })
    } else if (status === 'disconnected') {
      navigate(getUnauthedRedirectPath(), { replace: true })
    }
  }, [status, navigate])

  return (
    <Center>
      <Loading />
    </Center>
  )
}

export default RootRedirector
