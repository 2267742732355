import {
  Button,
  ButtonProps,
  Stack,
  StackProps,
  TextInput,
  TextInputProps,
  TextProps,
  createPolymorphicComponent,
} from '@mantine/core'
import styled from '@emotion/styled'
import { Link, LinkProps } from 'react-router-dom'

const styledStack = styled(Stack)`
  border: 0px inset white;
  border-radius: 0px;
`
export const StyledStack = createPolymorphicComponent<'div', StackProps>(styledStack)

const bodyStack = styled(Stack)`
  margin-top: 150px;
  margin-left: 205px;
  margin-right: 25px;
  max-width: 1500px;
  @media (max-width: 1400px) {
    margin-top: 100px;
    margin-left: 25px;
  }
  @media (max-width: 400px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`
export const BodyStack = createPolymorphicComponent<'div', StackProps>(bodyStack)

const styledLink = styled(Link)`
  text-decoration: none;
`
export const StyledLink = createPolymorphicComponent<'a', LinkProps>(styledLink)

const styledLinkAsButton = styled(Link)`
  border: 'none';
  background: 'none';
  cursor: 'pointer';
`
export const StyledLinkAsButton = createPolymorphicComponent<'a', TextProps>(styledLinkAsButton)

const styledRoundedButton = styled(Button)`
  border-radius: 50%;
  width: 5em;
  height: 5em;
  align-items: center;
`
export const StyledRoundedButton = createPolymorphicComponent<'button', ButtonProps>(
  styledRoundedButton
)

const styledTextInput = styled(TextInput)`
  border-radius: 12px;
  border: 2px solid #888;
`
export const StyledTextInput = createPolymorphicComponent<'text', TextInputProps>(styledTextInput)
