/* eslint-disable no-bitwise */
import type { UserInfo } from '@web3auth/base'
import type { CropInfo } from '@/components/MediaWrappers/ImageCrop'
import type { ItemCreator } from '../components/Studio/Templates/utils'

export interface AvatarOptions {
  avatars: string
  fileSize: number
}
export interface UserIdentity {
  version: string
  address: `0x${string}`
  username: string
  nickname: string
  profileImage: string
  avatarOptions: AvatarOptions[]
}
export interface Creator {
  id: number
  username: string
  address: string
  percentage?: number
  valid: boolean | undefined
}

export interface ResponseValues {
  [key: number]: string
}
export interface CommonRights {
  retainRights: boolean
  requireAttributions: boolean
  allowCommercialUse: boolean
  allowAI: boolean
}

export interface RoyaltyRecipients {
  salePercentage: number
  transferFee: number
  royaltyRecipients: {
    recipient: string
    percentage: number
  }[]
}

export interface Draft {
  // Needed for the stepper
  id: string
  activeStep: number
  resaleRoyaltiesChecked: boolean
  // Needed for rights questions
  activeRightsStep: number
  preQuestionIds: { [key: number]: number }
  responseValues: ResponseValues
  // Used for the preview
  cropInfo: CropInfo
  // Used for Minting
  creator: ItemCreator
  asset: string
  name: string
  description: string
  tags: string[]
  properties: { [key: string]: any }
  displayProperties: { key: string; value: string }[]
  rights: CommonRights
  royalties: RoyaltyRecipients
  storage: string
}
export type ImmutableDraft = DeepReadonly<Draft>

export interface ItemMetadata {
  creator: ItemCreator
  name: string
  description: string
  image: string
  cropInfo: CropInfo
  tags: string[]
  displayProperties: { key: string; value: string }[]
  behaviorProperties: { [key: string]: any }
  royalties: RoyaltyRecipients
  rights: CommonRights
}

export interface StoredTheme {
  isLightMode: boolean
}

export interface Theme {
  background: string
  background2: string
  background3: string
  title: string
  textAndIcon: string
  paragraph: string
  l1Primary: string
  l1Secondary: string
  l1PrimaryOpacity: string
  l1SecondaryOpacity: string
  notification: string
  splitter: string
  profileOutline: string
  popUpOutline: string
}

export interface Web3AuthContext {
  provider: string
  account: string
  userInfo: UserInfo
  balance: string
}

export interface ErrorMessageDisplay {
  message: string | JSX.Element
  details: string
}

/**
 * Truncates a string to a specified length and adds ellipses if necessary.
 * @param {string} str - The string to truncate.
 * @param {number} num - The number of characters to truncate the string to. Default is 12.
 * @returns {string} - The truncated string.
 * @example const str = 'Cristino Ronaldo'
 * const result = truncateString(str)
 * console.log(result) // Cristino Rona...
 * @example const str = 'Cristino Ronaldo'
 * const result = truncateString(str, 5)
 * console.log(result) // Crist...
 */

export const truncateString = (str: string, num = 12): string =>
  str.length > num ? `${str.slice(0, num > 3 ? num - 3 : num)}...` : str

/**
 * Shortens a hash to a specified length.
 * @param {string} hash - The hash to shorten.
 * @param {number} size - The number of characters to shorten the hash to. Default is 4.
 * @returns {string} - The shortened hash.
 * @example const hash = '0x1234567890abcdef'
 * const result = shortenHash(hash)
 * console.log(result) // 0x1234...cdef
 */
export const shortenHash = (hash: string, size: number = 4): string =>
  `${hash.slice(0, 2 + size)}...${hash.slice(-size)}`

/**
 * Converts a timestamp to a date and time string.
 * @param {number | string} timestamp - The timestamp to convert.
 * @returns {string} - The date and time string.
 * @example const timestamp = 1629993600000
 * const result = convertTimestampDateAndTime(timestamp)
 * console.log(result) // Aug 26, 2021, 6:00 PM
 */
export const convertTimestampDateAndTime = (timestamp: number | string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return new Intl.DateTimeFormat('default', options).format(new Date(timestamp))
}

/**
 * Converts a timestamp to a date string.
 * @param {number | string} timestamp - The timestamp to convert.
 * @returns {string} - The date string.
 * @example const timestamp = 1629993600000
 * const result = convertTimestampDate(timestamp)
 * console.log(result) // Aug 26, 2021
 */
export const convertTimestampDate = (timestamp: number | string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  return new Intl.DateTimeFormat('default', options).format(new Date(timestamp))
}

/**
 * Converts a timestamp to a time string.
 * @param {number | string} timestamp - The timestamp to convert.
 * @returns {string} - The time string.
 * @example const timestamp = 1629993600000
 * const result = convertTimestampTime(timestamp)
 * console.log(result) // 6:00 PM
 */
export const convertTimestampTime = (timestamp: number | string): string => {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return new Intl.DateTimeFormat('default', options).format(new Date(timestamp))
}

/**
 * Converts a timestamp to a date and time string in UTC.
 * @param {Date} timestamp - The timestamp to convert.
 * @returns {string} - The date and time string.
 * @example const timestamp = new Date('2021-08-31T18:00:00.000Z')
 * const result = convertTimestampDateAndTimeUTC(timestamp)
 * console.log(result) // 2021-08-31 18:00:00 (UTC)
 */
export const convertTimestampDateAndTimeUTC = (timestamp: Date): string => {
  // format: 2021-08-31 18:00:00 (UTC)
  const year = timestamp.getUTCFullYear().toString().padStart(4, '0')
  const month = (timestamp.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = timestamp.getUTCDate().toString().padStart(2, '0')
  const hours = timestamp.getUTCHours().toString().padStart(2, '0')
  const minutes = timestamp.getUTCMinutes().toString().padStart(2, '0')
  const seconds = timestamp.getUTCSeconds().toString().padStart(2, '0')

  const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return time
}

export const convertToEpochSeconds = (dateString: string) => {
  const dateFormat = 'Z'
  const date = new Date(dateString + dateFormat)
  return Math.floor(date.getTime() / 1000)
}

export const formatBalance = (balance: bigint): string => String(Number(balance) / 10 ** 18)

export const getKeyValue = (elem: any, index: number): string => `${elem}${String(index)}`

export const truncateIdentity = (identity: string) => {
  const addressStringSize = 13

  // Convert the string to an array of code points
  const codePoints = Array.from(identity)

  if (codePoints.length > addressStringSize) {
    // Convert the sliced array of code points back to a string
    return `${codePoints.slice(0, addressStringSize - 3).join('')}...`
  }

  // Convert the padded array of code points back to a string
  return codePoints.join('').padEnd(addressStringSize, '\u00A0')
}

export const post = async (url: string, payload: string) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'POST',
      },
      body: payload,
    })
    if (!response.ok) {
      if (response.body) {
        const data = await response.json()
        if (data) {
          throw new Error(`Error: ${data.error}`)
        }
      }
      throw new Error(response.statusText)
    }
    const data = await response.json()
    return data
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const capitalizeChar = (char: string | null): string => {
  if (!char) return ''
  return char.charAt(0).toUpperCase() + char.slice(1)
}

export const linkWalletType = (walletType: string | null): string => {
  if (!walletType) {
    return 'metamask'
  }
  if (walletType === 'jwt') {
    return 'email'
  }
  return 'social'
}

// Encode
export const utoa = (str: string): string => window.btoa(encodeURIComponent(str))

// Decode
export const atou = (str: string): string => decodeURIComponent(window.atob(str))

export const convertUnitToBytes = (value: number, unit: string) => {
  if (unit === 'KB') return value * 1024
  if (unit === 'MB') return value * (1024 * 1024)
  return value
}

/**
 * Converts bytes to human readable format
 * @param {number} value - The first number.
 * @returns {string} - The converted value into string.
 * @example const value = 2000
 * const result = convertBytesToUnit(value)
 * console.log(result) // 2KB
 */
export const convertBytesToUnit = (value: number) => {
  if (value < 1024) return `${value.toFixed(2)}B` // bytes
  if (value < 1024 * 1024) return `${(value / 1024).toFixed(2)}KB` // kilobytes
  return `${(value / (1024 * 1024)).toFixed(2)}MB` // megabytes
}

// converts data url to file
export const dataURLtoFile = (dataurl: string | undefined) => {
  if (!dataurl) return null
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = window.atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    n -= 1
    u8arr[n] = bstr.charCodeAt(n)
  }
  const filenamePart = arr[0].split(';')[1]
  const filenameMatch = filenamePart ? filenamePart.trim().match(/^filename=(.+)$/) : null
  const filename = filenameMatch ? filenameMatch[1] : 'unknown'

  return new File([u8arr], filename, { type: mime })
}

type AnyKey = keyof any
type IsLiteral<T> = T extends AnyKey
  ? string extends T
    ? false
    : number extends T
      ? false
      : symbol extends T
        ? false
        : true
  : false
type GetLiterals<T> = T extends AnyKey ? (IsLiteral<T> extends true ? T : never) : never

type ObjAttributes<O extends { [k: AnyKey]: unknown }, A extends AnyKey> = {
  // [K in A]: K extends GetLiterals<keyof O> ? (IsLiteral<K> extends true ? true : false) : unknown
  [K in GetLiterals<A>]: K extends GetLiterals<keyof O> ? true : false
}

/**
 * Checks if a json object contains the given attributes
 * @param {J} json - The json object to check.
 * @param {A} attributesToCheck - The attributes to check.
 * @returns {ObjAttributes<J, A>} - The attributes that are present in the json object.
 * @example const json = { a: 1, b: 2, c: 3 }
 * const attributesToCheck = ['a', 'b', 'd']
 * const result = containsJsonAttributes(json, attributesToCheck)
 * console.log(result) // { a: true, b: true, d: false }
 */
export const containsJsonAttributes = <J extends Record<AnyKey, unknown>, A extends AnyKey>(
  json: J,
  attributesToCheck: ReadonlyArray<A>
): ObjAttributes<J, A> => {
  const result = {} as ObjAttributes<J, A>
  attributesToCheck.forEach(attribute => {
    if (attribute === 'info') {
      ;(result as Record<AnyKey, boolean>)[attribute] =
        Object.prototype.hasOwnProperty.call(json, 'name') &&
        Object.prototype.hasOwnProperty.call(json, 'description')
      return
    }
    ;(result as Record<AnyKey, boolean>)[attribute] = Object.prototype.hasOwnProperty.call(
      json,
      attribute
    )
  })
  return result
}

/**
 * converts camelCase to text. e.g. camelCaseToText -> Camel Case To Text
 * @param {string} key - The first number.
 * @returns {string} - The converted value into string.
 */
export const camelCaseToText = (key: string) => {
  const result = key.replace(/([A-Z])/g, ' $1')
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

/**
 * Formats a number to a string with a suffix
 * @param {number} value - The first number.
 * @returns {string} - The converted value into string.
 * @example const value = 1000
 * const result = formatValueToSuffix(value)
 * console.log(result) // 1K
 */
export const formatValueToSuffix = (
  value: number,
  maxDigits: number = 4,
  decimalDigits: number = 2
) => {
  const formatNumber = (num: number, forceDecimal: boolean) => {
    let numStr = num.toFixed(forceDecimal ? decimalDigits : 0)
    const [intPart, decPart] = numStr.split('.')
    if (intPart.length > maxDigits) {
      // If integer part is longer than maxDigits, truncate and use decimal part
      const requiredIntDigits = Math.min(intPart.length, maxDigits)
      const formattedIntPart = intPart.substring(0, requiredIntDigits)
      const formattedDecPart = decPart ? decPart.substring(0, decimalDigits) : ''
      numStr = formattedIntPart + (formattedDecPart ? `.${formattedDecPart}` : '')
    }

    return numStr
  }

  // Less than 1000, just return the number
  if (value < 1000) return formatNumber(value, true)

  // Thousands
  if (value < 1000000) {
    // Check if the integer part of value is longer than maxDigits
    if (Math.floor(value).toString().length > maxDigits) {
      return `${formatNumber(value / 1000, true)}K`
    }

    // Check if the integer part of kValue is longer than maxDigits
    if (Math.floor(value / 1000).toString().length > maxDigits) {
      return `${formatNumber(value / 1000, true)}K`
    }

    // Integer part is not longer than maxDigits so display entire value
    return `${formatNumber(value, true)}`
  }

  // Millions
  // Check if the integer part of value is longer than maxDigits
  if (Math.floor(value).toString().length > maxDigits) {
    return `${formatNumber(value / 1000000, true)}M`
  }

  // Check if the integer part of kValue is longer than maxDigits
  if (Math.floor(value / 1000000).toString().length > maxDigits) {
    return `${formatNumber(value / 1000000, true)}M`
  }

  // Integer part is not longer than maxDigits so display entire value
  return `${formatNumber(value, true)}M`
}

export const formatNumberWithCommas = (value: number) =>
  value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const generatePermissions = (input: ResponseValues): CommonRights => {
  const result: CommonRights = {
    retainRights: false,
    requireAttributions: false,
    allowCommercialUse: false,
    allowAI: false,
  }

  if (Object.hasOwnProperty.call(input, 1) && input[1] === '0') {
    result.retainRights = true
  }

  if (Object.hasOwnProperty.call(input, 2) && input[2] === '1') {
    result.requireAttributions = true
  }

  if (Object.hasOwnProperty.call(input, 3) && input[3] === '1') {
    result.allowCommercialUse = true
  }

  if (Object.hasOwnProperty.call(input, 4) && input[4] === '1') {
    result.allowAI = true
  }

  return result
}

// Convert image string to bytes
export const convertImageToBytes = (image: string) => {
  const base64 = image.split(',')[1]
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}
// List of IPFS gateways
// export const ipfsDomains = [
//   'https://ipfs.io/ipfs/',
//   'nftstorage.link/',
//   'https://gateway.pinata.cloud/ipfs/',
//   'https://cf-ipfs.com/ipfs/',
//   'https://ipfs.infura.io/ipfs/',
// ]

// create list without boolean
export const ipfsDomains: string[] = [
  'https://azure-hilarious-mandrill-400.mypinata.cloud',
  'nftstorage.link',
  'https://ipfs.io',
  'dweb.link',
  'https://cloudflare-ipfs.com',
  'w3s.link',
  'https://cf-ipfs.com',
]

export const processIpfsDomain = (domain: string, ipfsHash: string): string => {
  if (domain.endsWith('.link')) {
    const splits = ipfsHash.split('/')
    const cid = splits[0]
    let path = ''
    if (splits.length > 1) {
      path = `/${splits[1]}`
    }
    return `https://${cid}.ipfs.${domain}${path}`
  }
  return `${domain}/ipfs/${ipfsHash}`
}

// converts IPFS uri to https uri gets json info
export const ipfsToUrl = (ipfsUrl: string): string => {
  const prefix = 'ipfs://'
  if (ipfsUrl?.startsWith(prefix)) {
    const link = processIpfsDomain(ipfsDomains[0], ipfsUrl.slice(prefix.length))
    return link
  }
  return 'https://placehold.co/300?text=Loading+Image&font=montserrat'
}

export const ipfsToUrlWithoutDomain = (ipfsUrl: string): string => {
  const prefix = 'ipfs://'
  if (ipfsUrl?.startsWith(prefix)) {
    const link = ipfsUrl.slice(prefix.length)
    return link
  }
  return ipfsUrl
}

// funtion to fetch IPFS, in case it fails retrys to 3 different links before failing
export const fetchIPFS = async (url: string, id?: string): Promise<any> => {
  if (!url) {
    return ''
  }

  // Replace {id} to id
  url = url.includes('{id}') && id ? url.replace('{id}', id) : url

  const fetchUntilSuccess = async (urls: string[]): Promise<any> => {
    try {
      const response = await fetch(urls[0])
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const data = await response.json()
      if (data.length === 0) {
        throw new Error('IPFS data is empty')
      }
      return data
    } catch (error: any) {
      if (urls.length > 1) {
        const remainingUrls = urls.slice(1)

        // add linear propagation delay
        await new Promise(resolve => {
          setTimeout(resolve, 12000 / urls.length)
        })

        // try next url
        return fetchUntilSuccess(remainingUrls)
      }
      throw new Error(error.message)
    }
  }

  // add ipfs gateway prefix
  const ipfsUrl = ipfsToUrlWithoutDomain(url)
  if (!ipfsUrl) {
    throw new Error('IPFS url is empty')
  }

  // try all ipfs gateways
  return fetchUntilSuccess(ipfsDomains.map(domain => processIpfsDomain(domain, ipfsUrl)))
}

export const convertCamelCaseToTitleCase = (input: string): string => {
  if (input === null || input === '') return input

  // Spot space between two uppercase letters
  const spaceBetweenUppercaseLetters = /([A-Z])\s([A-Z])/g

  return (
    input
      // Split the string at each uppercase letter and join with a space
      .split(/(?=[A-Z])/)
      .join(' ')
      // Uppercase the first character of each word
      .replace(/\b\w/g, char => char.toUpperCase())
      .replace(spaceBetweenUppercaseLetters, '$1$2')
  )
}

interface ImageDict {
  [key: string]: any[]
}

export const convertDictToString = (imageDict: ImageDict): string => {
  const imageTypes = Object.keys(imageDict)
  const imageTypesString = imageTypes.join(',')

  return imageTypesString
}

export const convertDictToText = (imageDict: ImageDict): string => {
  const imageTypes = Object.keys(imageDict)

  const fileExtensionsString = imageTypes.map(type => type.split('/')[1].toUpperCase()).join('/')

  return fileExtensionsString
}

export const wrapCreatorName = (name: string) => {
  // if address meaning starts with 0x and is 42 characters long
  if (name.startsWith('0x') && name.length === 42) {
    return shortenHash(name)
  }
  if (name.startsWith('@')) {
    return truncateIdentity(name)
  }

  return `@${truncateIdentity(name)}`
}

// Create hash based on list of strings
export const hashList = (values: string[]) => {
  let hash = 0
  for (let i = 0; i < values.length; i++) {
    for (let j = 0; j < values[i].length; j++) {
      const char = values[i].charCodeAt(j)
      hash = (hash << 5) - hash + char
      hash &= hash
    }
  }
  return hash.toString().slice(-5)
}

export const displayBalance = (value: bigint | undefined, resultDecimal: number = 4): string => {
  const networkDecimals = 18
  if (value !== undefined) {
    return (parseFloat(value.toString()) * 10 ** (-1 * networkDecimals)).toFixed(resultDecimal)
  }
  return parseFloat('0').toFixed(resultDecimal)
}

export type ProcessedBalance = {
  value: bigint
  display: string
  fullDisplay: string
}

export const processBalance = (balance: bigint): ProcessedBalance => {
  const displayed = displayBalance(balance)
  const displayedNumber = parseFloat(displayed)
  return {
    value: balance,
    display: formatValueToSuffix(displayedNumber, 3),
    fullDisplay: formatNumberWithCommas(displayedNumber),
  }
}

export const emptyBalance = {
  value: BigInt(0),
  display: '0',
  fullDisplay: '0',
}

export const REPORT_BUG =
  process.env.REPORT_BUG || 'https://blocksurvey.io/lamina1-hub-feedback-D.KIfzAfR9.0KNKCBAMGGQ?v=o'
