// L1 Native Config
export const NATIVE_CHAIN_NAME = `Lamina1 ${process.env.NATIVE_CHAIN_NAME}`
export const NATIVE_CHAIN_TICKER = 'L1'
export const NATIVE_CHAIN_LAUNCH_TICKER = process.env.NATIVE_CHAIN_LAUNCH_TICKER || 'LL1'
export const NATIVE_CHAIN_ID = Number(process.env.NATIVE_CHAIN_ID) || 43112
export const NATIVE_CHAIN_RPC = process.env.NATIVE_CHAIN_RPC || 'http://localhost:9650/ext/bc/C/rpc'
export const EXPLORER_URL = process.env.NATIVE_CHAIN_EXPLORER_URL || 'https://explorer.lamina1.com'

// Identity Subnet Config
export const IDENTITY_SUBNET_NAME = `Lamina1 ${process.env.IDENTITY_SUBNET_NAME}`
export const IDENTITY_SUBNET_TICKER = 'L1ID'
export const IDENTITY_SUBNET_ID = Number(process.env.IDENTITY_SUBNET_ID) || 12345
export const IDENTITY_SUBNET_RPC =
  process.env.IDENTITY_SUBNET_RPC || 'http://localhost:9650/ext/bc/identitytest/rpc'

// Identity Subnet Contracts
export const CONTRACT_CONTROLLER_ADDRESS =
  process.env.CONTRACT_CONTROLLER_ADDRESS || '0xa4DfF80B4a1D748BF28BC4A271eD834689Ea3407'
export const CONTRACT_RESOLVER_ADDRESS =
  process.env.CONTRACT_RESOLVER_ADDRESS || '0x8B3BC4270BE2abbB25BC04717830bd1Cc493a461'
export const CONTRACT_COLLECTOR_ADDRESS =
  process.env.CONTRACT_COLLECTOR_ADDRESS || '0x52C84043CD9c865236f11d9Fc9F56aa003c1f922'
export const CONTRACT_SPACES_REGISTRY_ADDRESS =
  process.env.CONTRACT_SPACES_REGISTRY_ADDRESS || '0xA4cD3b0Eb6E5Ab5d8CE4065BcCD70040ADAB1F00'

export const BLANK_ADDRESS = '0x0000000000000000000000000000000000000000'

// Airdrop Config
export const AIRDROP_CONTRACT_ADDRESS = (process.env.AIRDROP_CONTRACT_ADDRESS ||
  '0xc2A6ff1b31C69ae7c7DEAD49Ba44692AC55dFde7') as `0x${string}`
