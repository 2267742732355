import React from 'react'

import { Helmet } from 'react-helmet-async'

interface SeoProps {
  title: string
  description: string
  type: string
  creator: string
  url: string
  image: string
}

const Seo: React.FC<SeoProps> = ({ title, description, type, creator, url, image }) => (
  <Helmet prioritizeSeoTags>
    <title>{title}</title>
    <meta name="description" content={description} />

    {/* Open Graph */}
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={title} />

    {/* Twitter */}
    <meta name="twitter:creator" content={creator} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </Helmet>
)

export default Seo
