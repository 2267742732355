import type { FC } from 'react'
import { IconLoader } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAuth } from '@/plugins/auth'
import RootButton from './RootButton'
import Loading from '../Loading'

const Web3Button: FC<{ center?: boolean }> = ({ center = true }) => {
  const { t } = useTranslation()
  const { status, connect, disconnect } = useAuth()

  const sx = center ? { margin: 'auto' } : undefined

  if (status === 'connecting') {
    return (
      <RootButton sx={sx} disabled leftIcon={<IconLoader />}>
        {t('buttons.loadingWeb3Auth', 'Loading Web3Auth...')}
      </RootButton>
    )
  }

  if (status === 'connected') {
    return (
      <Link to="" onClick={disconnect}>
        {t('buttons.signOut', 'Sign Out')}
      </Link>
    )
  }

  if (status === 'disconnected') {
    return (
      <RootButton sx={sx} transparent onClick={connect}>
        {t('buttons.connect', 'Connect to Hub')}
      </RootButton>
    )
  }

  return <Loading />
}

export default Web3Button
