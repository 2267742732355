import { Center } from '@mantine/core'
import React, { memo, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuthedRedirectPath } from '@/plugins/auth/RouteRedirectors/utils'
import Loading from '@/components/Loading'
import { useAuth } from '@/plugins/auth'

interface NotAuthenticatedProps {
  children: ReactNode
  whileConnecting?: 'loader' | 'nothing' | 'child'
}

const NotAuthenticated = memo(({ children, whileConnecting = 'child' }: NotAuthenticatedProps) => {
  const { status } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (status === 'connected') {
      const to = getAuthedRedirectPath()
      navigate(to)
    }
  }, [status, navigate])

  if (status === 'connected') {
    // return nothing, as the effect will redirect
    return null
  }

  if (status === 'disconnected') {
    // if disconnected, just render the children
    return children
  }

  // if connecting, render a loading screen, the children or nothing
  return whileConnecting === 'loader' ? (
    <Center style={{ height: '100vh' }}>
      <Loading />
    </Center>
  ) : whileConnecting === 'child' ? (
    children
  ) : null
})
NotAuthenticated.displayName = 'NotAuthenticated'

function notAuthenticated(
  children: ReactNode,
  whileConnecting?: NotAuthenticatedProps['whileConnecting']
): ReactNode {
  return <NotAuthenticated whileConnecting={whileConnecting}>{children}</NotAuthenticated>
}

export default notAuthenticated
