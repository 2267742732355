import { ChainNamespaceType, CustomChainConfig, WALLET_ADAPTERS } from '@web3auth/base'
import { Web3Auth } from '@web3auth/modal'
import {
  LANGUAGE_TYPE,
  LANGUAGES,
  OPENLOGIN_NETWORK_TYPE,
  OpenloginAdapter,
} from '@web3auth/openlogin-adapter'
import { Options, Web3AuthConnector } from '@web3auth/web3auth-wagmi-connector'
import { toHex } from 'viem'
import { configureChains, createConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { publicProvider } from 'wagmi/providers/public'
import { lazySingleton } from '@/utils/helpers'
import {
  EXPLORER_URL,
  IDENTITY_SUBNET_ID,
  IDENTITY_SUBNET_NAME,
  IDENTITY_SUBNET_RPC,
  IDENTITY_SUBNET_TICKER,
  NATIVE_CHAIN_ID,
  NATIVE_CHAIN_NAME,
  NATIVE_CHAIN_RPC,
  NATIVE_CHAIN_TICKER,
} from '@/constants/blockchain'

interface NativeCurrency {
  name: string
  symbol: string
  decimals: number
}

export interface MetamaskChainConfig {
  chainId: string
  chainName: string
  nativeCurrency: NativeCurrency
  rpcUrls: string[]
  blockExplorerUrl?: string[]
  iconUrls?: string[]
}

// interface WagmiChainConfig extends Omit<Omit<MetamaskChainConfig, 'rpcUrls'>, 'nativeCurrency'> {
export interface WagmiChainConfig {
  name: string
  network: string
  id: number
  rpcUrls: {
    public: {
      http: string[]
    }
    default: {
      http: string[]
    }
  }
  blockExplorers: {
    default: {
      name: string
      url: string
    }
  }
  nativeCurrency: NativeCurrency
}

export interface Web3AuthChainConfig extends CustomChainConfig {}

/* -------------------------------------------------------------------------- */
/*                       General Network Configurations                       */
/* -------------------------------------------------------------------------- */
export const l1NativeConfig = {
  name: NATIVE_CHAIN_NAME,
  network: NATIVE_CHAIN_NAME.toLocaleLowerCase().replace(' ', '_'),
  chainId: NATIVE_CHAIN_ID || 7649,
  chainIdHex: toHex(NATIVE_CHAIN_ID || 7649),
  nativeCurrency: {
    name: 'Lamina',
    symbol: NATIVE_CHAIN_TICKER || 'L1',
    decimals: 18,
  },
  chainNamespace: 'eip155',
  rpcUrl: NATIVE_CHAIN_RPC,
  blockExplorerUrl: EXPLORER_URL,
}

export const l1IdSubnetConfig = {
  name: IDENTITY_SUBNET_NAME,
  network: IDENTITY_SUBNET_NAME.toLocaleLowerCase().replace(' ', '_'),
  chainId: IDENTITY_SUBNET_ID || 12345,
  chainIdHex: toHex(IDENTITY_SUBNET_ID || 12345),
  nativeCurrency: {
    name: 'L1Identity',
    symbol: IDENTITY_SUBNET_TICKER || 'L1ID',
    decimals: 18,
  },
  chainNamespace: 'eip155',
  rpcUrl: IDENTITY_SUBNET_RPC,
  blockExplorerUrl: EXPLORER_URL,
}

// NATIVE_CHAIN_NAME = "Lamina1 Testnet"
// NATIVE_CHAIN_ID = "764984"
// NATIVE_CHAIN_TICKER = "L1T"
// NATIVE_CHAIN_RPC = "https://subnets.avax.network/lamina1tes/testnet/rpc"
// NATIVE_CHAIN_EXPLORER_URL = "https://subnets-test.avax.network/lamina1tes"
export const fujiConfig = {
  name: 'Lamina1 Testnet',
  network: 'lamina1_testnet',
  chainId: 764984,
  chainIdHex: '0xbaadf00d',
  nativeCurrency: {
    name: 'Lamina',
    symbol: 'L1T',
    decimals: 18,
  },
  chainNamespace: 'eip155',
  rpcUrl: 'https://subnets.avax.network/lamina1tes/testnet/rpc',
  blockExplorerUrl: 'https://subnets-test.avax.network/lamina1tes',
}

/* -------------------------------------------------------------------------- */
/*                            Metamask Chain Config                           */
/* -------------------------------------------------------------------------- */
export const l1NativeMetamaskConfig: MetamaskChainConfig = {
  chainId: l1NativeConfig.chainIdHex,
  chainName: l1NativeConfig.name,
  nativeCurrency: l1NativeConfig.nativeCurrency,
  rpcUrls: [l1NativeConfig.rpcUrl],
  // blockExplorerUrl: [l1NativeConfig.blockExplorerUrl],
  iconUrls: [],
}

export const l1IdSubnetMetamaskConfig: MetamaskChainConfig = {
  chainId: l1IdSubnetConfig.chainIdHex,
  chainName: l1IdSubnetConfig.name,
  nativeCurrency: l1IdSubnetConfig.nativeCurrency,
  rpcUrls: [l1IdSubnetConfig.rpcUrl],
  // blockExplorerUrl: [l1IdSubnetConfig.blockExplorerUrl],
  iconUrls: [],
}

/* -------------------------------------------------------------------------- */
/*                            Web3Auth Chain Config                           */
/* -------------------------------------------------------------------------- */
export const l1NativeWeb3AuthConfig: Web3AuthChainConfig = {
  displayName: l1NativeConfig.name,
  chainId: l1NativeConfig.chainIdHex,
  chainNamespace: l1NativeConfig.chainNamespace as ChainNamespaceType,
  rpcTarget: l1NativeConfig.rpcUrl,
  blockExplorer: l1NativeConfig.blockExplorerUrl,
  ticker: l1NativeConfig.nativeCurrency.symbol,
  tickerName: l1NativeConfig.nativeCurrency.name,
  decimals: l1NativeConfig.nativeCurrency.decimals,
}

/* export const l1IdSubnetWeb3AuthConfig: Web3AuthChainConfig = {
  displayName: l1IdSubnetConfig.name,
  chainId: l1IdSubnetConfig.chainIdHex,
  chainNamespace: l1IdSubnetConfig.chainNamespace as ChainNamespaceType,
  rpcTarget: l1IdSubnetConfig.rpcUrl,
  blockExplorer: l1IdSubnetConfig.blockExplorerUrl,
  ticker: l1IdSubnetConfig.nativeCurrency.symbol,
  tickerName: l1IdSubnetConfig.nativeCurrency.name,
  decimals: l1IdSubnetConfig.nativeCurrency.decimals,
} */

/* -------------------------------------------------------------------------- */
/*                             Wagmi Chain Config                             */
/* -------------------------------------------------------------------------- */
export const l1NativeWagmiConfig: WagmiChainConfig = {
  name: l1NativeConfig.name,
  network: l1NativeConfig.network,
  id: l1NativeConfig.chainId,
  rpcUrls: {
    public: {
      http: [l1NativeConfig.rpcUrl],
    },
    default: {
      http: [l1NativeConfig.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Lamina1 Explorer',
      url: l1NativeConfig.blockExplorerUrl,
    },
  },
  nativeCurrency: l1NativeConfig.nativeCurrency,
  // ...l1NativeMetamaskConfigWithoutElements,
}

export const l1IdSubnetWagmiConfig: WagmiChainConfig = {
  name: l1IdSubnetConfig.name,
  network: l1IdSubnetConfig.network,
  id: l1IdSubnetConfig.chainId,
  rpcUrls: {
    public: {
      http: [l1IdSubnetConfig.rpcUrl],
    },
    default: {
      http: [l1IdSubnetConfig.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Lamina1 Explorer',
      url: l1IdSubnetConfig.blockExplorerUrl,
    },
  },
  nativeCurrency: l1IdSubnetConfig.nativeCurrency,
  // ...l1IdSubnetMetamaskConfigWithoutElements,
}

export const fujiWagmiConfig: WagmiChainConfig = {
  name: fujiConfig.name,
  network: fujiConfig.network,
  id: fujiConfig.chainId,
  rpcUrls: {
    public: {
      http: [fujiConfig.rpcUrl],
    },
    default: {
      http: [fujiConfig.rpcUrl],
    },
  },
  blockExplorers: {
    default: {
      name: 'Lamina1 Explorer',
      url: fujiConfig.blockExplorerUrl,
    },
  },
  nativeCurrency: fujiConfig.nativeCurrency,
  // ...fujiMetamaskConfigWithoutElements,
}

export const getChains = lazySingleton(() =>
  configureChains([l1NativeWagmiConfig, l1IdSubnetWagmiConfig, fujiWagmiConfig], [publicProvider()])
)

function isNetworkType(value: any): value is OPENLOGIN_NETWORK_TYPE {
  return ['mainnet', 'cyan', 'testnet'].includes(value)
}

const web3AuthNetwork: OPENLOGIN_NETWORK_TYPE = isNetworkType(process.env.WEB3AUTH_NETWORK)
  ? process.env.WEB3AUTH_NETWORK
  : 'testnet'
// Web3 Auth Config
const WEB3AUTH_CLIENT_ID =
  process.env.WEB3AUTH_CLIENT_ID ||
  'BH0Cp1N7-v87NV1_4HmkQCuRuvDP5X5321etOkf46Rk1Y7_G4X_7LRH5TzG3S9K2GyH3jiIkVMGQLKg8RBY_nZM'
const initModalParams = {
  modalConfig: {
    // Disable WalletConnect
    [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
      label: 'wallet_connect',
      showOnModal: false,
    },
    // Disable Torus
    [WALLET_ADAPTERS.TORUS_EVM]: {
      label: 'Torus Wallet',
      showOnModal: false,
    },
    [WALLET_ADAPTERS.OPENLOGIN]: {
      label: 'openlogin',
      loginMethods: {
        // Disable some social logins
        apple: {
          name: 'apple',
          showOnModal: false,
        },
        twitch: {
          name: 'twitch',
          showOnModal: false,
        },
        facebook: {
          name: 'facebook',
          showOnModal: false,
        },
        reddit: {
          name: 'reddit',
          showOnModal: false,
        },
        line: {
          name: 'line',
          showOnModal: false,
        },
        github: {
          name: 'github',
          showOnModal: false,
        },
        wechat: {
          name: 'wechat',
          showOnModal: false,
        },
        kakao: {
          name: 'kakao',
          showOnModal: false,
        },
        linkedin: {
          name: 'linkedin',
          showOnModal: false,
        },
        weibo: {
          name: 'weibo',
          showOnModal: false,
        },
        // Disable sms_passwordless
        sms_passwordless: {
          name: 'sms_passwordless',
          showOnModal: false,
        },
      },
    },
  },
} satisfies Omit<Options, 'web3AuthInstance'>

function getDefaultLanguage(): LANGUAGE_TYPE | undefined {
  // default to english
  localStorage.setItem('i18nextLng', 'en-US')
  const i18nextLocale = localStorage.getItem('i18nextLng') ?? undefined
  if (!i18nextLocale || i18nextLocale.length < 2) return undefined

  const i18nextLng = i18nextLocale.substring(0, 2) as LANGUAGE_TYPE
  if (!(Object.values(LANGUAGES) as LANGUAGE_TYPE[]).includes(i18nextLng)) return undefined

  return i18nextLng
}
export const getWeb3AuthInstance = lazySingleton(() => {
  const web3Auth = new Web3Auth({
    // Native Lamina1 Chain defined as the default chain
    chainConfig: l1NativeWeb3AuthConfig,
    clientId: WEB3AUTH_CLIENT_ID,
    web3AuthNetwork,
    uiConfig: {
      appName: 'LAMINA1',
      logoLight:
        'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
      logoDark:
        'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
      // TODO: if the user's browser settings prefer dark theme, but then the user changes to light theme,
      //       the auth modal will still be in dark theme because the "auto" option always gets the setting
      //       from the browser. To fix this, the user-defined theme needs to be used here instead of "auto".
      //       A HOC needs to be created to wrap the WagmiConfig provider and replace the "auto" option with
      //       the user-defined theme. Same thing would happen if the browser's setting is light theme and
      //       the user changes to dark theme.
      mode: 'auto',
      loginMethodsOrder: ['discord', 'google', 'twitter'],
      defaultLanguage: getDefaultLanguage(),
      loginGridCol: 2, // 2 | 3
      primaryButton: 'externalLogin', // "externalLogin" | "socialLogin" | "emailLogin",
    },
    enableLogging: false,
  })
  web3Auth.configureAdapter(
    new OpenloginAdapter({
      loginSettings: {
        mfaLevel: 'optional',
      },
      adapterSettings: {
        network: web3AuthNetwork,
        uxMode: 'redirect',
        redirectUrl: `${window.location.origin}/loggedIn`,
        replaceUrlOnRedirect: true,
        whiteLabel: {
          appName: 'Lamina1',
          logoDark:
            'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
          logoLight:
            'https://uploads-ssl.webflow.com/63fe332d7b9ae4159d741e55/640b9679f321ab6b6af536fd_favicon.jpg',
          defaultLanguage: 'en', // en, de, ja, ko, zh, es, fr, pt, nl
          // TODO: if the user's browser settings prefer dark theme, but then the user changes to light theme,
          //       the auth modal will still be in dark theme because the "auto" option always gets the setting
          //       from the browser. To fix this, the user-defined theme needs to be used here instead of "auto".
          //       A HOC needs to be created to wrap the WagmiConfig provider and replace the "auto" option with
          //       the user-defined theme. Same thing would happen if the browser's setting is light theme and
          //       the user changes to dark theme.
          mode: 'auto', // whether to enable dark mode. defaultValue: false
        },
      },
    })
  )

  return web3Auth
})

export const getWeb3AuthConnector = lazySingleton(
  () =>
    new Web3AuthConnector({
      chains: getChains().chains,
      options: {
        web3AuthInstance: getWeb3AuthInstance(),
        ...initModalParams,
      },
    })
)

export const getInjectedConnector = lazySingleton(() => {
  const { chains } = getChains()
  return new InjectedConnector({ chains })
})

export const getWagmiConfig = lazySingleton(() => {
  const { publicClient, webSocketPublicClient } = getChains()

  const injectedConnector = getInjectedConnector()

  return createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: [getWeb3AuthConnector(), injectedConnector],
  })
})
