import { TFunction } from 'i18next'
import React from 'react'
import { ActionIcon, createStyles, useMantineTheme } from '@mantine/core'
import { IconCheck, IconCopy, IconLink } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import useCopyClipboard from '../../hooks/useCopyToClipboard'
import Snackbar from '../Snackbar'
import copiedClipboard_green from '../../assets/icons/copiedClipboard_green.svg'
import copiedClipboard_blue from '../../assets/icons/copiedClipboard_blue.svg'
import IconWrapper from '../MediaWrappers/IconWrapper'

interface StyleParams {
  transparent: boolean
}

const useStyles = createStyles((theme, { transparent }: StyleParams) => ({
  rightActionButton: {
    border: 'none',
    backgroundColor: transparent ? 'transparent' : theme.colors.background3[0],
    color: theme.colors.title[0],
    '&:hover': {
      backgroundColor: theme.colors.l1Primary[0],
      color: theme.colors.background[0],
      stroke: theme.colors.background[0],
    },
  },
  copyMessageIcon: {
    paddingTop: '4px',
    width: '13px',
    height: '13px',
  },
  shareActionIcon: {
    cursor: 'pointer',
    color: theme.colors.textAndIcon[0],
    padding: '5px',
    height: '60px',
    width: '60px',
    backgroundColor: theme.colors.background3[0],
    borderRadius: '12px',
    border: `2px solid ${theme.colors.profileOutline[0]}`,
    '&:hover': {
      backgroundColor: theme.colors.l1Primary[0],
    },
  },
  linkIcon: {
    transform: 'rotate(45deg)',
  },
}))

export const callbackCopyMessage =
  (t: TFunction) =>
  // eslint-disable-next-line react/display-name
  (value: string, { copyMessageIcon }: any) => (
    <Snackbar
      value={value}
      title={t('common.copyButtonTitle', 'Copied to clipboard')}
      icon={
        <IconWrapper
          classes={copyMessageIcon}
          iconDark={copiedClipboard_green}
          iconLight={copiedClipboard_blue}
        />
      }
    />
  )

interface CopyButtonProps {
  value: string
  transparent?: boolean
  share?: boolean
}

const CopyButton: React.FC<CopyButtonProps> = ({ value, transparent = false, share = false }) => {
  const { t } = useTranslation()
  const { classes } = useStyles({ transparent })
  const [isCopied, staticCopy] = useCopyClipboard()
  const theme = useMantineTheme()
  return (
    <ActionIcon
      className={share ? classes.shareActionIcon : classes.rightActionButton}
      variant="outline"
      onClick={() => {
        staticCopy(value, classes, theme, callbackCopyMessage(t))
      }}
    >
      {share ? (
        <IconLink size="32px" className={classes.linkIcon} />
      ) : isCopied ? (
        <IconCheck size="16px" />
      ) : (
        <IconCopy size="16px" />
      )}
    </ActionIcon>
  )
}

export default CopyButton
