import React from 'react'
import { Center, Container } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'

/**
 * This is a contextual modal specified on ModalsProvider. It can be used anywhere in the app,
 * and provides context and inner properties of the individual modal.
 *
 * In this case, the modal should accept a value that will then be rendered as a QR code
 * in the modal body.
 */
const QRCodeModal: React.FC<ContextModalProps<{ modalBody: string }>> = ({ innerProps }) => (
  <Container>
    <Center>{innerProps.modalBody}</Center>
  </Container>
)

export default QRCodeModal
