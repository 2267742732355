import React, { useMemo } from 'react'
import { Stack, ActionIcon, createStyles } from '@mantine/core'
import { IconBrandDiscord, IconBrandX } from '@tabler/icons-react'
import { Link, useLocation } from 'react-router-dom'
import iconicBlack from '../assets/icons/iconic_black.svg'
import iconicWhite from '../assets/icons/iconic_white.svg'
import IconWrapper from './MediaWrappers/IconWrapper'

const useStyles = createStyles(theme => ({
  footer: {
    padding: '25px 0',
    zIndex: 201,
    // top: 'calc(var(--document-height) * 0.5)',
    flexDirection: 'row',
    margin: 'auto',
    alignItems: 'center',
  },
  socialIcon: {
    cursor: 'pointer',
    color: theme.colors.background[0],
    padding: '5px',
    height: '40px',
    width: '40px',
    backgroundColor: theme.colors.textAndIcon[0],
    borderRadius: '50%',
    border: 'none',
  },
  iconicLogo: {
    height: '24px',
    width: '24px',
  },
}))

// TODO: Is `isItemPage` in `Footer` supposed to be true in the `/items` page too, or just the `/item/:id` pages?
//       If it's supposed to be true in the `/items` page too, then this regex needs to be updated.
//       I believe what was meant was just the `/item/:id` pages, so I selected the regex to reflect that.

// Regex for /item/:id pages (optional trailing slash):
const itemPageRegex = /^\/item\/[^/]+\/?$/

// Regex for both /items AND /item/:id pages (optional trailing slash):
// const itemPageRegex = /^\/item(?:\/[^/]+|s)\/?$/

const Footer: React.FC = () => {
  const { classes } = useStyles()
  const { pathname } = useLocation()
  const isItemPage = useMemo(() => !!pathname.match(itemPageRegex), [pathname])

  return (
    <>
      {!isItemPage && (
        <Stack className={classes.footer}>
          {/* Discord */}
          <Link to="https://discord.com/invite/lamina1" target="_blank">
            <ActionIcon className={classes.socialIcon}>
              <IconBrandDiscord size="2em" />
            </ActionIcon>
          </Link>
          {/* Lamina1 */}
          <Link to="https://www.lamina1.com/" target="_blank">
            <ActionIcon className={classes.socialIcon}>
              <IconWrapper
                classes={classes.iconicLogo}
                iconDark={iconicBlack}
                iconLight={iconicWhite}
              />
            </ActionIcon>
          </Link>
          {/* Twitter */}
          <Link to="https://twitter.com/Lamina1official" target="_blank">
            <ActionIcon className={classes.socialIcon}>
              <IconBrandX size="2em" />
            </ActionIcon>
          </Link>
        </Stack>
      )}
    </>
  )
}

export default Footer
