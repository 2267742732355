import { type Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import {
  type AuthActionConnectInit,
  type AuthActionDisconnectInit,
  AuthActionType,
} from '@/plugins/auth/AuthProvider/types/AuthActions'
import {
  AuthSteps,
  type InternalAuthState,
  isConnectingStep,
  isDisconnectingStep,
} from '@/plugins/auth/AuthProvider/types/AuthInternalState'

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export default function _useConnectDisconnect(
  internalState: InternalAuthState,
  dispatch: Dispatch<AuthActionConnectInit | AuthActionDisconnectInit>
) {
  // The point of a queued action is to wait for the current action to finish before doing the
  // next one, so as to not disturb the state machine's normal flow. Queued actions will only
  // trigger once the FSM reaches a valid state.
  const [queuedAuthAction, setQueuedAuthAction] = useState<'connect' | 'disconnect' | undefined>()
  const connect = useCallback(() => {
    setQueuedAuthAction('connect')
  }, [])
  const disconnect = useCallback(() => {
    setQueuedAuthAction('disconnect')
  }, [])

  // This effect is used to handle the queued action.
  useEffect(() => {
    if (
      !queuedAuthAction ||
      (internalState.step !== AuthSteps.Connected && internalState.step !== AuthSteps.Disconnected)
    )
      return

    if (queuedAuthAction === 'connect') {
      if (isConnectingStep(internalState.step) || internalState.step === AuthSteps.Connected) return
      dispatch({
        type: AuthActionType.ConnectInit,
      })
    } else if (queuedAuthAction === 'disconnect') {
      if (isDisconnectingStep(internalState.step) || internalState.step === AuthSteps.Disconnected)
        return
      dispatch({
        type: AuthActionType.DisconnectInit,
      })
    }

    setQueuedAuthAction(undefined)
  }, [dispatch, internalState, internalState.step, queuedAuthAction])

  return useMemo(() => ({ connect, disconnect }) as const, [connect, disconnect])
}
