import type { OpenloginUserInfo } from '@web3auth/openlogin-adapter/dist/types/interface'
import type { L1Provider } from '@/plugins/auth/AuthProvider/types/context'
import type { UserIdentity } from '@/utils/utils'

export enum AuthSteps {
  Init = 'Init',

  DisconnectingInit = 'DisconnectingInit',
  DisconnectingWagmi = 'DisconnectingWagmi',
  DisconnectingLogoutWeb3Auth = 'DisconnectingLogoutWeb3Auth',
  Disconnected = 'Disconnected',

  ConnectingInit = 'ConnectingInit',
  ConnectingWagmi = 'ConnectingWagmi',
  ConnectingUserInfo = 'ConnectingUserInfo',
  ConnectingUserIdentity = 'ConnectingUserIdentity',
  ConnectingProvider = 'ConnectingProvider',
  ConnectError = 'ConnectError',

  Connected = 'Connected',
}

export const DISCONNECTING_STEPS = [
  AuthSteps.DisconnectingInit,
  AuthSteps.DisconnectingWagmi,
  AuthSteps.DisconnectingLogoutWeb3Auth,
] as const
export type DisconnectingSteps = (typeof DISCONNECTING_STEPS)[number]
export function isDisconnectingStep(step: AuthSteps): step is DisconnectingSteps {
  return (DISCONNECTING_STEPS as readonly AuthSteps[]).includes(step)
}
export const CONNECTING_STEPS = [
  AuthSteps.ConnectingInit,
  AuthSteps.ConnectingWagmi,
  AuthSteps.ConnectingUserInfo,
  AuthSteps.ConnectingUserIdentity,
  AuthSteps.ConnectingProvider,
] as const
export type ConnectingStates = (typeof CONNECTING_STEPS)[number]
export function isConnectingStep(step: AuthSteps): step is ConnectingStates {
  return (CONNECTING_STEPS as readonly AuthSteps[]).includes(step)
}

// Constant that contains the valid state transitions. Edit this to change the accepted transitions.
// { [newState]: [validOldState1, validOldState2, ...] }
// null means that the state is valid from any state.
const VALID_TRANSITIONS = {
  // No state should ever move back to Init
  [AuthSteps.Init]: [],
  [AuthSteps.DisconnectingInit]: [
    AuthSteps.Init,
    AuthSteps.Connected,
    AuthSteps.Disconnected,
    // Errors during the connection process will trigger
    // a disconnection, so this transition needs to be valid
    ...CONNECTING_STEPS,
  ],
  [AuthSteps.DisconnectingWagmi]: [AuthSteps.DisconnectingInit],
  [AuthSteps.DisconnectingLogoutWeb3Auth]: [AuthSteps.DisconnectingWagmi],
  [AuthSteps.Disconnected]: [
    AuthSteps.Init,
    // If the login modal is closed, the connection flow must be cancelled,
    // thus the ConnectingWagmi state can go to Disconnected.
    AuthSteps.ConnectingWagmi,
    ...DISCONNECTING_STEPS,
  ],
  [AuthSteps.ConnectingInit]: [AuthSteps.Init, AuthSteps.Disconnected, AuthSteps.Connected],
  [AuthSteps.ConnectingWagmi]: [AuthSteps.ConnectingInit],
  [AuthSteps.ConnectingUserInfo]: [AuthSteps.Init, AuthSteps.ConnectingWagmi],
  [AuthSteps.ConnectingUserIdentity]: [AuthSteps.ConnectingUserInfo],
  [AuthSteps.ConnectingProvider]: [AuthSteps.ConnectingUserIdentity],
  [AuthSteps.Connected]: [AuthSteps.ConnectingProvider],
  [AuthSteps.ConnectError]: [AuthSteps.Connected, ...CONNECTING_STEPS],
} as const satisfies {
  [newState in AuthSteps]: Exclude<AuthSteps, newState>[] | null
}

export function throwIfInvalidStateTransition(
  oldState: InternalAuthState,
  newState: InternalAuthState
): InternalAuthState {
  const validTransitionsFromNewState = VALID_TRANSITIONS[newState.step] as AuthSteps[] | null
  if (
    // Null represents a state that can be transitioned from any other state
    validTransitionsFromNewState !== null &&
    oldState.step !== newState.step &&
    !validTransitionsFromNewState.includes(oldState.step)
  ) {
    throw new Error(
      `Invalid state transition from ${oldState.step} to ${newState.step}.\nOld: ${JSON.stringify(
        oldState
      )}\nNew: ${JSON.stringify(newState)}`
    )
  }

  return newState
}

export type InternalAuthStateJustMounted = {
  step: AuthSteps.Init
}

export type InternalAuthStateDisconnectingInit = {
  step: AuthSteps.DisconnectingInit
}
export type InternalAuthStateDisconnectingWagmi = {
  step: AuthSteps.DisconnectingWagmi
}
export type InternalAuthStateDisconnectingLogoutWeb3Auth = {
  step: AuthSteps.DisconnectingLogoutWeb3Auth
}
export type InternalAuthStateDisconnected = {
  step: AuthSteps.Disconnected
}

export type InternalAuthStateConnectingInit = {
  step: AuthSteps.ConnectingInit
}
export type InternalAuthStateConnectingWagmi = {
  step: AuthSteps.ConnectingWagmi
}
export type InternalAuthStateConnectingUserInfo = {
  step: AuthSteps.ConnectingUserInfo

  address: `0x${string}`
}
export type InternalAuthStateConnectingUserIdentity = {
  step: AuthSteps.ConnectingUserIdentity

  address: `0x${string}`
  userInfo: Partial<OpenloginUserInfo>
}
export type InternalAuthStateConnectingProvider = {
  step: AuthSteps.ConnectingProvider

  address: `0x${string}`
  userInfo: Partial<OpenloginUserInfo>
  userIdentity: UserIdentity
}
export type InternalAuthStateConnectError = {
  step: AuthSteps.ConnectError

  error: Error
  address: `0x${string}` | undefined
  userInfo: Partial<OpenloginUserInfo> | undefined
  userIdentity: UserIdentity | undefined
  provider: L1Provider | undefined
}
export type InternalAuthStateConnected = {
  step: AuthSteps.Connected

  address: `0x${string}`
  userInfo: Partial<OpenloginUserInfo>
  userIdentity: UserIdentity
  provider: L1Provider
}

export type InternalAuthState =
  | InternalAuthStateJustMounted
  | InternalAuthStateDisconnectingInit
  | InternalAuthStateDisconnectingWagmi
  | InternalAuthStateDisconnectingLogoutWeb3Auth
  | InternalAuthStateDisconnected
  | InternalAuthStateConnectingInit
  | InternalAuthStateConnectingWagmi
  | InternalAuthStateConnectingUserInfo
  | InternalAuthStateConnectingUserIdentity
  | InternalAuthStateConnectingProvider
  | InternalAuthStateConnectError
  | InternalAuthStateConnected

export const initState = {
  step: AuthSteps.Init,
} as InternalAuthState
