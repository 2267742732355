import React from 'react'
import { Alert, Text, Title, createStyles, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Trans, useTranslation } from 'react-i18next'
import useLocalStorage from '../hooks/useLocalStorage'

interface StyleParams {
  isMobile: boolean
}
const useStyles = createStyles((theme, { isMobile }: StyleParams) => ({
  disclaimer: {
    backgroundColor: theme.colors.l1Primary[0],
    button: {
      width: '1.5em',
      height: '1.5em',
      padding: '0.25em',
      borderRadius: '8px',
      color: theme.colors.background[0],
      '&:hover': {
        border: `1px solid ${theme.colors.background[0]}`,
        color: theme.colors.background[0],
        // backgroundColor: theme.colors.title[0],
      },
    },
  },
  title: {
    color: theme.colors.background[0],
    fontFamily: 'monument-grotesk-heavy',
    fontSize: isMobile ? '14px' : '18px',
    textTransform: 'uppercase',
  },
  text: {
    color: theme.colors.background[0],
    textAlign: 'left',
    fontSize: isMobile ? '12px' : '16px',
  },
}))

const Disclaimer: React.FC = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { classes } = useStyles({ isMobile })
  const [disclaimer, setDisclaimer] = useLocalStorage<boolean>('disclaimer', true)

  return (
    <>
      {disclaimer && (
        <Alert
          title={
            <Title order={4} className={classes.title}>
              {t('components.disclaimer.title', 'NOW IN BETA')}
            </Title>
          }
          radius="12px"
          className={classes.disclaimer}
          withCloseButton
          closeButtonLabel={t('buttons.close', 'Close')}
          onClose={() => setDisclaimer(false)}
        >
          <Text className={classes.text}>
            <Trans i18nKey="components.disclaimer.body1">
              The LAMINA1 Betanet is a prototype platform that was developed and tested by the
              Lamina1 core team and community from August 2023-April 2024. This site is now operated
              and managed by the Open Metaverse Foundation, and will remain live as a testbed for
              development.
            </Trans>
          </Text>
          <br />
          <Text className={classes.text}>
            <Trans i18nKey="components.disclaimer.body2">
              Any L1 Betanet tokens collected here are for testing purposes only and should only be
              transferred between Beta test participants.
            </Trans>
          </Text>
        </Alert>
      )}
    </>
  )
}

export default Disclaimer
