import {
  ActionIcon,
  Avatar,
  Container,
  Grid,
  Stack,
  Title,
  Text,
  createStyles,
  Tooltip,
  Divider,
  Menu,
  Box,
} from '@mantine/core'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Web3Button from '@/components/Buttons/Web3Button'
import { useAuth } from '@/plugins/auth'
import { UserIdentity, shortenHash, truncateIdentity } from '../../utils/utils'
import CopyButton from '../Buttons/CopyButton'
import useLocalStorage from '../../hooks/useLocalStorage'

interface StyleParams {
  menuOpened: boolean
  username: string | undefined
}

const truncate = (str: string, maxLength: number) => {
  if (str.length > maxLength && str.split(' ')[0].length > maxLength) {
    return `${str.substring(0, maxLength - 3)}...`
  }
  return str
}

const useStyles = createStyles((theme, { menuOpened, username }: StyleParams) => ({
  container: {
    width: 216,
    position: 'absolute',
    zIndex: 100,
    borderRadius: '16px',
    backgroundColor: menuOpened ? theme.colors.background2[0] : 'transparent',
    padding: menuOpened ? '15px' : '0px',
    margin: menuOpened ? '-15px' : '0px',
    ':hover': {
      padding: '15px',
      margin: '-15px',
      backgroundColor: theme.colors.background2[0],
    },
  },
  userInfoColumn: {
    width: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '7px',
    maxHeight: '62px',
    alignItems: 'flex-start',
    ':hover': {
      backgroundColor: theme.colors.background2[0],
    },
  },
  username: {
    textAlign: 'left',
    maxWidth: '220px',
    maxHeight: '28px',
    wordBreak: 'break-all',
  },
  address: {
    color: theme.colors.l1Primary[0],
    fontFamily: username ? 'monument-grotesk-bold' : 'monospace',
    fontSize: '14px',
    fontWeight: 700,
  },
  dropdown: {
    width: 'auto !important',
    top: '0 !important',
    left: '0 !important',
    marginTop: '10px',
    position: 'relative',
    backgroundColor: theme.colors.background2[0],
    borderRadius: '16px',
    border: `0px solid ${theme.colors.background2[0]}`,
    ':hover': {
      backgroundColor: theme.colors.background2[0],
    },
  },
  menuItems: {
    marginTop: '15px',
    textAlign: 'left',
    width: 'fit-content',
    fontFamily: 'monument-grotesk-heavy',
    '> *': {
      fontSize: '18px',
      textDecoration: 'none',
    },
  },
  buttonColumn: {
    padding: '4px 0',
    maxHeight: '62px',
  },
  logout: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '> svg': {
      marginRight: '5px',
    },
  },
  copyIcon: {
    '&:hover': {
      border: `0px solid ${theme.colors.background[0]}`,
      color: theme.colors.title[0],
    },
  },
  chevronActionIcon: {
    color: theme.colors.title[0],
    '&:hover': {
      border: `0px solid ${theme.colors.background[0]}`,
    },
  },
  divider: {
    borderTopColor: theme.colors.splitter[0],
    height: '1px',
    width: '100%',
  },
}))

const UserHeader: React.FC = () => {
  const { t } = useTranslation()
  const { address } = useAuth()
  const [menuOpened, setMenuOpened] = useState<boolean>(false)
  const [userIdentity] = useLocalStorage<UserIdentity>(`userIdentity-${address}`)
  const { classes } = useStyles({
    menuOpened,
    username: userIdentity?.username,
  })

  const displayAddress = useMemo(() => {
    if (!address) {
      return ''
    }
    if (userIdentity?.username) {
      return userIdentity.username
    }
    return address
  }, [userIdentity?.username, address])

  return (
    <Container className={classes.container}>
      <Menu
        trigger="hover"
        openDelay={500}
        closeDelay={100000}
        opened={menuOpened}
        onClose={() => setMenuOpened(false)}
        closeOnClickOutside
        closeOnEscape
      >
        {displayAddress && userIdentity ? (
          <Menu.Target>
            <Grid sx={{ padding: 0 }} onClick={() => setMenuOpened(previous => !previous)}>
              {userIdentity.profileImage && (
                <Grid.Col span="content">
                  <Avatar radius="6px" size="48px" src={userIdentity.profileImage} />
                </Grid.Col>
              )}
              <Grid.Col span="auto" className={classes.userInfoColumn}>
                <Tooltip
                  withArrow
                  position="top"
                  label={userIdentity.nickname}
                  style={{
                    display: userIdentity.nickname.length > 12 ? 'inline' : 'none',
                  }}
                >
                  <Title
                    order={4}
                    className={classes.username}
                    style={{ fontSize: '16px', overflow: 'hidden' }}
                  >
                    {truncate(userIdentity.nickname, 12)}
                  </Title>
                </Tooltip>
                <Tooltip
                  withArrow
                  position="top"
                  label={displayAddress}
                  style={{
                    display: displayAddress.length > 13 ? 'inline' : 'none',
                  }}
                >
                  <Text className={classes.address}>
                    {userIdentity.username
                      ? `@${truncateIdentity(displayAddress)}`
                      : shortenHash(displayAddress)}
                  </Text>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span="content" className={classes.buttonColumn}>
                <ActionIcon
                  className={classes.chevronActionIcon}
                  variant="transparent"
                  // onClick={() => setMenuOpened(previous => !previous)}
                >
                  {menuOpened ? (
                    <IconChevronUp size={14} stroke={5} />
                  ) : (
                    <IconChevronDown size={14} stroke={5} />
                  )}
                </ActionIcon>

                <Box onClick={event => event.stopPropagation()}>
                  <CopyButton value={displayAddress} transparent />
                </Box>
              </Grid.Col>
            </Grid>
          </Menu.Target>
        ) : (
          <Web3Button center={false} />
        )}
        <Menu.Dropdown className={classes.dropdown}>
          <Divider className={classes.divider} />
          <Stack className={classes.menuItems}>
            <Link to="/usersettings">{t('components.userHeader.settings', 'Settings')}</Link>
            <Link to="/helpcenter">{t('components.userHeader.helpCenter', 'Help Center')}</Link>
            <Web3Button center={false} />
          </Stack>
        </Menu.Dropdown>
      </Menu>
    </Container>
  )
}

export default UserHeader
