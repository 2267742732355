import { useCallback, useMemo } from 'react'
import { createPublicClient, http, namehash, parseEther, EstimateContractGasParameters } from 'viem'
import { normalize } from 'viem/ens'
import { getWalletClient, waitForTransaction } from '@wagmi/core'
import { useAuth } from '@/plugins/auth'
import useConst from '@/hooks/useConst'
import {
  getWeb3AuthInstance,
  l1IdSubnetWagmiConfig,
  l1NativeWagmiConfig,
  WagmiChainConfig,
} from '../plugins/auth/config'
import { formatBalance } from '../utils/utils'
import { CONTRACT_COLLECTOR_ADDRESS, CONTRACT_RESOLVER_ADDRESS } from '../constants/blockchain'
import L1Resolver from '../contracts/PublicResolver.json'
import L1PaymentCollector from '../contracts/L1NSPaymentCollector.json'

export const networkOptions = {
  l1: 'LAMINA1',
  avax: 'Avalanche',
} as const

export type NetworkOptions = (typeof networkOptions)[keyof typeof networkOptions]

export const usePublicWagmiProvider = (chainConfig: WagmiChainConfig) => {
  const publicClient = useConst.fn(() =>
    createPublicClient({
      chain: chainConfig,
      transport: http(),
    })
  )

  /* -------------------------------------------------------------------------- */
  /*                               Public Actions                               */
  /* -------------------------------------------------------------------------- */
  const estimateGas = useCallback(
    async (address: string, destinationAddress: string, value: string): Promise<number> => {
      try {
        const gas = await publicClient.estimateGas({
          account: address as `0x${string}`,
          to: destinationAddress as `0x${string}`,
          value: parseEther(value),
        })
        // Convert BigInt to number and from wei to gwei
        return Number(gas) * 1e-9
      } catch (error: any) {
        console.error('Error', error)
        throw new Error(`Error estimating gas: ${error.message}`)
      }
    },
    [publicClient]
  )

  const estimateContractGas = useCallback(
    async (args: EstimateContractGasParameters): Promise<number> => {
      try {
        const gas = await publicClient.estimateContractGas(args)
        // Convert BigInt to number and from wei to gwei
        return Number(gas) * 1e-9
      } catch (error: any) {
        console.error('Error', error)
        throw new Error(`Error estimating gas: ${error.message}`)
      }
    },
    [publicClient]
  )

  const getGasPrice = useCallback(async (): Promise<number> => {
    try {
      const gasPrice = await publicClient.getGasPrice()
      // Convert BigInt to number and from wei to gwei
      return Number(gasPrice) * 1e-9
    } catch (error: any) {
      console.error('Error', error)
      throw new Error(`Error getting gas price: ${error.message}`)
    }
  }, [publicClient])

  const hasCode = useCallback(
    async (address: string): Promise<boolean> => {
      try {
        const code = await publicClient.getBytecode({
          address: address as `0x${string}`,
        })
        return code !== undefined && code !== '0x'
      } catch (error: any) {
        console.error('Error', error)
        throw new Error(`Error getting code: ${error.message}`)
      }
    },
    [publicClient]
  )

  return useMemo(
    () => ({
      estimateGas,
      estimateContractGas,
      getGasPrice,
      hasCode,
    }),
    [estimateGas, estimateContractGas, getGasPrice, hasCode]
  )
}

export const useIdSubnet = () => {
  const publicClientSubnet = useConst.fn(() =>
    createPublicClient({
      chain: l1IdSubnetWagmiConfig,
      transport: http(),
    })
  )

  const getBalance = useCallback(
    async (address: string): Promise<string> => {
      try {
        const balance = await publicClientSubnet.getBalance({
          address: address as `0x${string}`,
        })
        return formatBalance(balance)
      } catch (error: any) {
        console.error('Error', error)
        throw new Error(`Error getting balance on ID Subnet: ${error.message}`)
      }
    },
    [publicClientSubnet]
  )

  const getL1nsName = useCallback(
    async (address: string): Promise<string> => {
      try {
        const name = normalize(`${String(address).substring(2)}.addr.reverse`)
        const node = namehash(name)
        const data = await publicClientSubnet.readContract({
          address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
          abi: L1Resolver.abi,
          functionName: 'name',
          args: [node],
        })
        return (data as string).split('.', 1)[0] ?? ''
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Error getting L1NS Username from address ${address}.\n
      Error Message: ${error.message}`)
      }
    },
    [publicClientSubnet]
  )

  const getL1nsAddress = useCallback(
    async (handle: string): Promise<string> => {
      try {
        const name = normalize(`${handle}.l1`)
        const node = namehash(name)
        const data = await publicClientSubnet.readContract({
          address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
          abi: L1Resolver.abi,
          functionName: 'addr',
          args: [node],
        })
        return data as string
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Error getting L1NS Address from username ${handle}.\n
      Error Message: ${error.message}`)
      }
    },
    [publicClientSubnet]
  )

  const getL1nsNickname = useCallback(
    async (handle: string): Promise<string> => {
      const name = normalize(`${handle}.l1`)
      const node = namehash(name)
      try {
        const data = await publicClientSubnet.readContract({
          address: CONTRACT_RESOLVER_ADDRESS as `0x${string}`,
          abi: L1Resolver.abi,
          functionName: 'text',
          args: [node, 'displayname'],
        })
        return data as string
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Error getting L1NS Nickname from username: ${error.message}`)
      }
    },
    [publicClientSubnet]
  )

  return { getBalance, getL1nsName, getL1nsAddress, getL1nsNickname }
}

// TODO: Try to refactor async wagmi/auth getters by hooks
export const useEthProvider = () => {
  const { provider, address } = useAuth()

  const getWagmiWalletClient = useCallback(async () => {
    const wallet = await getWalletClient({
      chainId: l1NativeWagmiConfig.id,
    })
    return wallet
  }, [])

  const publicClient = useConst.fn(() =>
    createPublicClient({
      chain: l1NativeWagmiConfig,
      transport: http(),
    })
  )

  /* -------------------------------------------------------------------------- */
  /*                               Public Actions                               */
  /* -------------------------------------------------------------------------- */
  const estimateGas = useCallback(async (): Promise<number> => {
    try {
      const gas = await publicClient.estimateGas({
        account: address as `0x${string}`,
        to: address as `0x${string}`,
      })
      // Convert BigInt to number and from wei to gwei
      return Number(gas) * 1e-9
    } catch (error: any) {
      console.error('Error', error)
      throw new Error(`Error estimating gas: ${error.message}`)
    }
  }, [address, publicClient])

  const getGasPrice = useCallback(async (): Promise<number> => {
    try {
      const gasPrice = await publicClient.getGasPrice()
      // Convert BigInt to number and from wei to gwei
      return Number(gasPrice) * 1e-9
    } catch (error: any) {
      console.error('Error', error)
      throw new Error(`Error getting gas price: ${error.message}`)
    }
  }, [publicClient])

  /* -------------------------- Contract Interactions ------------------------- */
  const getPremiumMinBalance = useCallback(
    async (handle: string): Promise<number> => {
      try {
        // Read price from contract
        const price = await publicClient.readContract({
          address: CONTRACT_COLLECTOR_ADDRESS as `0x${string}`,
          abi: L1PaymentCollector.abi,
          functionName: 'price',
          args: [handle],
        })
        return parseFloat(formatBalance(price as any))
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(
          `Error getting Premium Username price from Payment Collector contract: ${error.message}`
        )
      }
    },
    [publicClient]
  )

  /* -------------------------------------------------------------------------- */
  /*                               Wallet Actions                               */
  /* -------------------------------------------------------------------------- */
  const getAccounts = useCallback(async (): Promise<string[] | undefined> => {
    try {
      const walletClient = await getWagmiWalletClient()
      if (!walletClient) {
        console.error('No wallet client found')
        return undefined
      }
      const accounts = await walletClient.getAddresses()
      return accounts as string[]
    } catch (error: any) {
      console.error('Error', error)
      throw new Error(`Error getting accounts: ${error.message}`)
    }
  }, [getWagmiWalletClient])

  const getBalance = useCallback(async (): Promise<string> => {
    try {
      const balance = await publicClient.getBalance({
        address: address as `0x${string}`,
      })
      return formatBalance(balance)
    } catch (error: any) {
      console.error('Error', error)
      throw new Error(`Error getting balance on L1 C-chain: ${error.message}`)
    }
  }, [address, publicClient])

  // TODO: Temporary solution. User is not supposed to have access to the priv key in the first place and the web3AuthInstance should not be used anywhere in the app
  const getPrivateKey = useCallback(async (): Promise<string> => {
    const p =
      provider ??
      (() => {
        const web3AuthInstance = getWeb3AuthInstance()
        if (!web3AuthInstance) {
          console.error('No web3AuthInstance found')
        }
        return web3AuthInstance?.provider
      })()

    try {
      const privateKey = await p?.request({
        method: 'eth_private_key',
      })
      return String(privateKey)
    } catch (error: any) {
      console.error('Error', error)
      throw new Error(`Error getting Web3Auth account private key: ${error.message}`)
    }
  }, [provider])

  const signAndSendTransaction = useCallback(
    async (destinationAddress: string, value: string): Promise<string | null> => {
      try {
        const walletClient = await getWagmiWalletClient()
        if (!walletClient) {
          console.error('No wallet client found')
          return null
        }
        const hash = await walletClient.sendTransaction({
          account: address as `0x${string}`,
          to: destinationAddress as `0x${string}`,
          value: parseEther(value),
        })
        const data = await waitForTransaction({
          hash,
        })
        return String(data.transactionHash)
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(`Failed to Send transaction: ${error.message}`)
      }
    },
    [address, getWagmiWalletClient]
  )

  /* -------------------------- Contract Interactions ------------------------- */
  const makePremiumUsernamePurchase = useCallback(
    async (handle: string, price: number): Promise<void> => {
      const walletClient = await getWagmiWalletClient()
      if (!walletClient) {
        console.error('No wallet client found')
        return
      }
      try {
        // Write purchase to contract
        await walletClient.writeContract({
          address: CONTRACT_COLLECTOR_ADDRESS as `0x${string}`,
          abi: L1PaymentCollector.abi,
          functionName: 'purchase',
          args: [handle],
          value: parseEther(String(price)),
        })
      } catch (error: any) {
        console.error('Error:', error)
        throw new Error(
          `Error making Premium Username purchase from Payment Collector contract: ${error.message}`
        )
      }
    },
    [getWagmiWalletClient]
  )

  return useMemo(
    () => ({
      estimateGas,
      getGasPrice,
      getPremiumMinBalance,
      getAccounts,
      getBalance,
      getPrivateKey,
      signAndSendTransaction,
      makePremiumUsernamePurchase,
    }),
    [
      estimateGas,
      getGasPrice,
      getPremiumMinBalance,
      getAccounts,
      getBalance,
      getPrivateKey,
      signAndSendTransaction,
      makePremiumUsernamePurchase,
    ]
  )
}
