import React, { useCallback, useEffect } from 'react'
import { Stack, Title, Text, createStyles, useMantineTheme, Grid, Modal } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconDotsVertical, IconShare2 } from '@tabler/icons-react'
import { Trans, useTranslation } from 'react-i18next'
import { useAuth } from '@/plugins/auth'
import Web3Button from '@/components/Buttons/Web3Button'
import useLocalStorage from '@/hooks/useLocalStorage'
import logoDark from '../assets/lamina1_white.png'
import logoLight from '../assets/lamina1_black.png'
import patternDark from '../assets/pattern.jpg'
import patternLight from '../assets/pattern_light.jpg'
import Disclaimer from './Disclaimer'
import IconWrapper from './MediaWrappers/IconWrapper'
import iconDark from '../assets/L1logo_lg_512_light.png'
import iconLight from '../assets/L1logo_lg_512_dark.png'

const useStyles = createStyles(theme => ({
  landingGraphic: {
    height: '98vh',
    width: '57vw',
    objectFit: 'cover',
    // mobile version
    [`@media (max-width: ${theme.breakpoints.lg})`]: {
      width: '98vw',
      height: '57vh',
    },
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      width: '98vw',
      height: '50vh',
    },
  },
  grid: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 30,
    width: 135,
    margin: 'auto',
  },
  title: {
    color: theme.colors.textAndIcon[0],
  },
  mobileMessage: {
    flexDirection: 'column',
    gap: '2em',
    height: '100vh',
    margin: '0 2em',
    overflow: 'none',
  },
  termsPolicy: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  termsPolicyText: {
    color: theme.colors.textAndIcon[0],
    fontSize: '10px',
  },
  disconnected: {
    flexDirection: 'row',
  },
  disconnectedInfo: {
    justifyContent: 'center',
    margin: 'auto',
    width: '200px',
    height: '100%',
  },
  disconnectedDisclaimer: {
    position: 'fixed',
    bottom: 0,
    margin: '1em',
    '@media (max-width: 1269px)': {
      top: 0,
      bottom: '60vh',
    },
  },
  modalLogo: {
    height: 45,
    width: 45,
    margin: 'auto',
    borderRadius: '10px',
  },
  modalIntro: {
    flexDirection: 'row',
    gap: '10px',
  },
  modalInstructions: {
    flexDirection: 'row',
    gap: '2px',
    justifyContent: 'center',
    backgroundColor: theme.colors.background3[0],
    padding: '10px 0',
    borderRadius: '0 0 10px 10px',
  },
  addToHomeScreenTitle: {
    fontSize: '18px',
  },
}))

const AddToHomeScreen: React.FC = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const { classes } = useStyles()
  const isSafariUser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  return (
    <Stack spacing="16px">
      <Stack>
        <Stack className={classes.modalIntro}>
          <IconWrapper classes={classes.modalLogo} iconDark={iconDark} iconLight={iconLight} />
          <Text>
            {t(
              'components.landingPage.pwaMessage',
              'Add our app in your home screen for a better experience.'
            )}
          </Text>
        </Stack>
        <Text sx={{ fontSize: '12px' }}>
          {t(
            'components.landingPage.pwaNote',
            `If you don't see the 'Add to Home Screen' option, please try switching to Safari. If it's still unavailable there, kindly ignore this message.`
          )}
        </Text>
      </Stack>
      <Stack className={classes.modalInstructions}>
        {isSafariUser ? (
          <>
            <Trans i18nKey="components.landingPage.pwaInstructionsSafari" transWrapTextNodes>
              <Text>Just tap</Text>
              <IconShare2 style={{ color: theme.colors.l1Primary[0] }} size={22} />
              <Text>then &apos;Add to Home Screen&apos;</Text>
            </Trans>
          </>
        ) : (
          <>
            <Trans i18nKey="components.landingPage.pwaInstructions" transWrapTextNodes>
              <Text>Just tap</Text>
              <IconDotsVertical style={{ color: theme.colors.l1Primary[0] }} size={22} />
              <Text>then &apos;Add to Home Screen&apos;</Text>
            </Trans>
          </>
        )}
      </Stack>
    </Stack>
  )
}

const TermsPolicy: React.FC = () => {
  const { classes } = useStyles()
  return (
    <Stack className={classes.termsPolicy}>
      <Trans i18nKey="components.landingPage.termsAndPolicy" transWrapTextNodes>
        <Text className={classes.termsPolicyText}>
          By continuing, you agree to our <br />
          <a href="https://lamina1.com/terms-and-conditions" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          {' and '}
          <a href="https://lamina1.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </Text>
      </Trans>
    </Stack>
  )
}

const LandingPage: React.FC<{ loggedIn?: boolean }> = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const [opened, { open, close }] = useDisclosure(false)
  const { status } = useAuth()
  const [appInstructions, setAppInstructions] = useLocalStorage<boolean>('appInstructions', true)
  // to cover all ipads and iphones
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}`)
  const isNotDesktop = useMediaQuery(`(max-width: 1000px)`)
  const appMode = 'standalone' in window.navigator && window.navigator.standalone

  useEffect(() => {
    if (!appMode && appInstructions && isNotDesktop) {
      open()
    }
  }, [appInstructions, appMode, isNotDesktop, open])

  const modalCLose = useCallback(() => {
    localStorage.setItem('appInstructions', 'false')
    setAppInstructions(false)
    close()
  }, [close])

  return (
    <>
      {status !== 'connected' && (
        <Grid className={classes.grid}>
          <Grid.Col span={12} xs={12} sm={12} md={12} lg={7}>
            <IconWrapper
              classes={classes.landingGraphic}
              iconDark={patternDark}
              iconLight={patternLight}
            />
          </Grid.Col>
          <Grid.Col span={12} xs={12} sm={12} md={12} lg={5} sx={{ margin: isMobile ? '25px' : 0 }}>
            <Stack className={classes.disconnectedInfo} spacing="2em">
              <IconWrapper classes={classes.logo} iconDark={logoDark} iconLight={logoLight} />
              <Web3Button />
              <TermsPolicy />
            </Stack>
            <Stack className={classes.disconnectedDisclaimer}>
              <Disclaimer />
            </Stack>
          </Grid.Col>
        </Grid>
      )}
      <Modal.Root opened={opened} onClose={modalCLose} lockScroll={false}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <Title className={classes.addToHomeScreenTitle}>
                {t('components.landingPage.addToHomeScreen', 'Add L1 Hub to your Home Screen')}
              </Title>
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <AddToHomeScreen />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  )
}

export default LandingPage
