import { ActionIcon } from '@mantine/core'
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack'
import React, { useMemo } from 'react'
import { StoredTheme, Theme } from '../utils/utils'
import useLocalStorage from '../hooks/useLocalStorage'
import { darkTheme, lightTheme } from '../components/Theme/themes'

const SnackbarCloseButton: React.FC<{
  snackbarKey: SnackbarKey
  activeTheme: Theme
}> = ({ snackbarKey, activeTheme }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <ActionIcon
      onClick={() => closeSnackbar(snackbarKey)}
      sx={{
        color: activeTheme.title,
        fontSize: '12px',
        marginBottom: '32px',
        '&:hover': {
          backgroundColor: activeTheme.l1Primary,
          color: activeTheme.background,
        },
      }}
    >
      ✕
    </ActionIcon>
  )
}

interface SnackbarCustomProviderProps {
  children: React.ReactNode
}

const SnackbarCustomProvider: React.FC<SnackbarCustomProviderProps> = ({ children }) => {
  const [userTheme] = useLocalStorage<StoredTheme>(`userTheme`)
  const activeTheme = useMemo(
    () => (userTheme?.isLightMode ? lightTheme : darkTheme),
    [userTheme?.isLightMode]
  )
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      preventDuplicate
      autoHideDuration={5000}
      style={{
        borderRadius: '16px',
        backgroundColor: activeTheme.background2,
        border: `2px solid ${activeTheme.popUpOutline}`,
        color: activeTheme.title,
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      action={key => <SnackbarCloseButton snackbarKey={key} activeTheme={activeTheme} />}
    >
      {children}
    </SnackbarProvider>
  )
}

export default SnackbarCustomProvider
