import { Center, StackProps, Text, createStyles } from '@mantine/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import loading_black from '../assets/gifs/l1_loading_black.gif'
import loading_white from '../assets/gifs/l1_loading_white.gif'
import loading_black_bg2 from '../assets/gifs/l1_loading_bg2_dark.gif'
import loading_white_bg2 from '../assets/gifs/l1_loading_bg2_white.gif'
import IconWrapper from './MediaWrappers/IconWrapper'

interface StyleParams {
  dashboard: boolean
  size: string
  imageLoaderHeight: string
}
const useStyles = createStyles((theme, { dashboard, size, imageLoaderHeight }: StyleParams) => ({
  center: {
    flexDirection: 'column',
    height: imageLoaderHeight,
  },
  loadingText: {
    textAlign: 'start',
    marginBottom: '1em',
    fontSize: '10pt',
    color: theme.colors.textAndIcon[0],
    fontFamily: 'monument-grotesk-regularitalic',
  },
  loadingGif: {
    height: size || (dashboard ? '90px' : '250px'),
    padding: size ? '0px' : '',
  },
}))

interface LoadingProps extends StackProps {
  dashboard?: boolean
  background2?: boolean
  noText?: boolean
  size?: string
  imageLoaderHeight?: string
}

const Loading: React.FC<LoadingProps> = ({
  dashboard = true,
  background2 = false,
  noText = false,
  size = '',
  imageLoaderHeight = '100%',
  ...props
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles({ dashboard, size, imageLoaderHeight })

  return (
    <Center className={classes.center} {...props}>
      {!noText && <Text className={classes.loadingText}>{t('common.loading', 'Loading...')}</Text>}
      <IconWrapper
        classes={classes.loadingGif}
        iconDark={background2 ? loading_black_bg2 : loading_black}
        iconLight={background2 ? loading_white_bg2 : loading_white}
      />
    </Center>
  )
}

export default Loading
