import './App.css'
import { ApolloProvider } from '@apollo/client'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import React, { Suspense, useEffect } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import { AuthProvider } from '@/plugins/auth'
import ErrorBoundaryFallback from '@/components/ErrorBoundaryFallback'
import AppFrame from './components/AppFrame'
import QRCodeModal from './components/Modals/QRCodeModal'
import Seo from './components/Seo'
import { useCustomTheme } from './components/Theme/themes'
import useLocalStorage from './hooks/useLocalStorage'
import client from './plugins/apollo'
import { I18NextProvider } from './plugins/i18n'
import SnackbarProvider from './plugins/SnackbarProvider'
import { StoredTheme } from './utils/utils'

const contextModals = {
  qrCode: QRCodeModal,
}

function App() {
  const [userTheme] = useLocalStorage<StoredTheme>(`userTheme`, {
    isLightMode: window?.matchMedia?.('(prefers-color-scheme: light)')?.matches ?? false,
  })

  const { activeTheme, mantineTheme } = useCustomTheme(userTheme.isLightMode)

  useEffect(() => {
    // Update CSS variables to match the active theme
    document.documentElement.style.setProperty('--background-color', activeTheme.background)
    document.documentElement.style.setProperty('--background2-color', activeTheme.background2)
    document.documentElement.style.setProperty('--background3-color', activeTheme.background3)
    document.documentElement.style.setProperty('--title-color', activeTheme.title)
    document.documentElement.style.setProperty('--textAndIcon-color', activeTheme.textAndIcon)
    document.documentElement.style.setProperty('--profileOutline-color', activeTheme.profileOutline)
    document.documentElement.style.setProperty('--text2-color', activeTheme.paragraph)
    document.documentElement.style.setProperty('--l1Primary-color', activeTheme.l1Primary)
    document.documentElement.style.setProperty('--l1Secondary-color', activeTheme.l1Secondary)
  }, [activeTheme])

  return (
    <React.StrictMode>
      <Suspense fallback={<div>Still loading...</div>}>
        <I18NextProvider>
          <ApolloProvider client={client}>
            <div className="App">
              <MantineProvider theme={mantineTheme}>
                <HelmetProvider>
                  <Seo
                    title="Lamina1"
                    description="Welcome to the LAMINA1 Hub, your gateway to the open metaverse. This is a testing and development ground for the LAMINA1 Betanet. Come build with us."
                    type="website"
                    creator="Lamina1"
                    url="https://hub.lamina1.com"
                    image="./logo_og.jpeg"
                  />
                  <SnackbarProvider>
                    <ModalsProvider modals={contextModals}>
                      <AuthProvider>
                        <AppFrame />
                      </AuthProvider>
                    </ModalsProvider>
                  </SnackbarProvider>
                </HelmetProvider>
              </MantineProvider>
            </div>
          </ApolloProvider>
        </I18NextProvider>
      </Suspense>
    </React.StrictMode>
  )
}

export default withErrorBoundary(App, {
  FallbackComponent: ErrorBoundaryFallback,
})
