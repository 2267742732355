import React from 'react'
import classNames from 'classnames'
import { Button, ButtonProps, createStyles, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

interface StyleParams {
  darkMode: boolean
  transparent: boolean
  color: string
  borderColor: string
  noBorder: boolean
  style1: boolean
  expand: boolean
  backAnimation: boolean
  isNotDesktop: boolean
}
// TODO: add style2 (button send on send page, e.g. and add icon left space)
const useStyles = createStyles(
  (
    theme,
    {
      darkMode,
      transparent,
      noBorder,
      color,
      borderColor,
      style1,
      expand,
      backAnimation,
      isNotDesktop,
    }: StyleParams
  ) => ({
    rootButton: {
      width: 'fit-content',
      minWidth: 'fit-content',
      height: style1 ? '48px' : '36px',
      backgroundColor: transparent
        ? 'transparent'
        : darkMode
          ? theme.colors.background[0]
          : theme.colors.title[0],
      color: color || (darkMode ? theme.colors.title[0] : theme.colors.background[0]),
      border: noBorder ? 'none' : '2px solid',
      borderColor:
        borderColor ||
        (transparent ? 'none' : darkMode ? theme.colors.title[0] : theme.colors.background[0]),
      borderRadius: '12px',
      padding: isNotDesktop ? '7px 22px' : '',
      fontSize: '13px',
      lineHeight: 14,
      fontFamily: 'monument-grotesk-black',
      flexGrow: expand ? 1 : 0,
      label: {
        // font adds small extra space at the bottom -> compensate with padding up to center)
        paddingTop: '2px',
      },

      '&:disabled': {
        backgroundColor: darkMode ? theme.colors.background[0] : theme.colors.title[0],
        color: darkMode ? theme.colors.title[0] : theme.colors.background[0],
        borderColor: darkMode ? theme.colors.title[0] : theme.colors.background[0],
        opacity: 0.5,
      },
      // first span on hover
      '&:hover span:first-of-type': {
        marginRight: backAnimation ? '5px' : '',
      },
    },
  })
)

export interface RootButtonProps extends ButtonProps {
  onClick?: () => void
  darkMode?: boolean
  transparent?: boolean
  borderColor?: string
  noBorder?: boolean
  style1?: boolean
  expand?: boolean
  backAnimation?: boolean
}

const RootButton: React.FC<RootButtonProps> = ({
  onClick,
  transparent = false,
  darkMode = true,
  color = '',
  borderColor = '',
  noBorder = false,
  style1 = true,
  expand = false,
  backAnimation = false,
  ...props
}) => {
  const theme = useMantineTheme()
  const isNotDesktop = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`)
  const { classes } = useStyles({
    darkMode,
    transparent,
    color,
    borderColor,
    noBorder,
    style1,
    expand,
    backAnimation,
    isNotDesktop,
  })

  return (
    <>
      <Button
        className={classNames([props.className, classes.rootButton])}
        {...props}
        onClick={onClick}
      />
    </>
  )
}

export default RootButton
