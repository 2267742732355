import { MutableRefObject, useEffect, useRef } from 'react'

/**
 * Returns a ref that is updated on every render.
 * When the component unmounts, the ref is set to undefined.
 *
 * This function is useful, for example, to save a useState setter
 * function that will be used in an async function, to make sure
 * it doesn't leak after the component unmounts, which would
 * cause a memory leak and an error in development with StrictMode
 * enabled.
 * @param val
 */
export default function useMountedRef<T>(val: T): MutableRefObject<T | undefined> {
  const ref = useRef<T | undefined>(val)
  useEffect(() => {
    ref.current = val
    return () => {
      // on unmount replace function with undefined
      ref.current = undefined
    }
  }, [val])
  return ref
}
