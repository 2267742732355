// Delete locally stored `userIdentity-${address}` if version key does include avatarHash variable
import { IconAlertCircle } from '@tabler/icons-react'
import { TFunction } from 'i18next'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { ReactNode } from 'react'
import avatar_01 from '@/assets/avatar/01.png'
import avatar_02 from '@/assets/avatar/02.png'
import avatar_03 from '@/assets/avatar/03.png'
import avatar_04 from '@/assets/avatar/04.png'
import Snackbar from '@/components/Snackbar'
import { BLANK_ADDRESS } from '@/constants/blockchain'
import { InternalAuthStateConnectingUserIdentity } from '@/plugins/auth/AuthProvider/types/AuthInternalState'
import { noop } from '@/utils/helpers'
import { AvatarOptions, hashList, UserIdentity } from '@/utils/utils'

const version = hashList([avatar_01, avatar_02, avatar_03, avatar_04])

// Default avatar options
const avatarDefaultOptions = [
  { avatars: avatar_01, fileSize: 0 },
  { avatars: avatar_02, fileSize: 0 },
  { avatars: avatar_03, fileSize: 0 },
  { avatars: avatar_04, fileSize: 0 },
]

export function deleteUserIdentityIfDifferentVersion(userIdentity: UserIdentity): UserIdentity {
  console.log('deleteUserIdentityIfDifferentVersion', version, userIdentity)
  const sameVersion = Object.entries(userIdentity || {}).some(
    elem =>
      // compare version to version
      (elem[0] === 'version' && elem[1] === version) ||
      // create hash from avatarOptions avatars and compare to version
      (elem[0] === 'avatarOptions' &&
        hashList(
          Object.entries(elem[1])
            .slice(-avatarDefaultOptions.length)
            .map((avatar: any) => avatar.avatars)
        ) === version)
  )
  // If version is the same, update locally stored `userIdentity-${address}` with new avatarOptions
  if (!sameVersion) {
    localStorage.removeItem(`userIdentity-${userIdentity.address}`)
    return {
      ...userIdentity,
      version,
    }
  }
  return userIdentity
}

export function getSavedProfileImage(
  userIdentity: UserIdentity
): { avatarOptions: AvatarOptions[]; profileImage: string } | undefined {
  if (!userIdentity) return undefined

  // Get locally stored avatarOptions to persist across versions
  const avatars = userIdentity?.avatarOptions ?? avatarDefaultOptions

  const newAvatarOptions =
    avatars.length > avatarDefaultOptions.length
      ? avatars.slice(0, avatars.length - avatarDefaultOptions.length)
      : []
  const avatarOptions = [...newAvatarOptions, ...avatarDefaultOptions]

  // Get locally stored profile image to persist across versions
  const profileImage: string = userIdentity.profileImage ?? avatar_01

  // if profileImage comes from userInfo, use it
  if (profileImage === userIdentity.profileImage) {
    return { avatarOptions, profileImage }
  }

  // previous profileImage is not in the avatarOptions list, use default
  if (!avatarOptions?.some((avatar: any) => avatar.avatars === profileImage)) {
    // if profileImage does not include an underscore it must not be an avatar neither an uploaded image that was carried over from a previous version, use default avatar
    if (
      !profileImage.includes('_') &&
      !profileImage.includes('ipfs') &&
      !profileImage.includes('http') &&
      !profileImage.includes('https')
    ) {
      return { avatarOptions, profileImage: avatar_01 }
    }
    // match string name of profileImage with avatarDefaultOptions index, p.e. if profileImage is "*!/01_*", use avatar_01 from avatarDefaultOptions, if '*/02_*', use avatar_02, etc.

    // get the split from the last '/'
    const firstSplit = profileImage.split('/').slice(-1)
    if (firstSplit.length > 0) {
      const profileImageIndex = Number(firstSplit[0].split('_')[0]) || 1
      if (profileImageIndex > avatarDefaultOptions.length) {
        return { avatarOptions, profileImage: avatar_01 }
      }
      return {
        avatarOptions,
        profileImage: avatarDefaultOptions[profileImageIndex - 1].avatars,
      }
    }
    // if no '/' is found, split with '_' and use first element as index
    const secondSplit = profileImage.split('_')
    const profileImageIndex = Number(secondSplit[0]) || 1
    if (profileImageIndex > avatarDefaultOptions.length) {
      return { avatarOptions, profileImage: avatar_01 }
    }
    return {
      avatarOptions,
      profileImage: avatarDefaultOptions[profileImageIndex - 1].avatars,
    }
  }
  return { avatarOptions, profileImage }
}

export function getUserIdentityPromise(
  state: InternalAuthStateConnectingUserIdentity,
  getL1nsName: (address: string) => Promise<string>,
  getL1nsNickname: (handle: string) => Promise<string>,
  t: TFunction
): Promise<UserIdentity> {
  const { address, userInfo } = state
  const cachedUserIdentityStr = localStorage.getItem(`userIdentity-${address}`)
  if (!cachedUserIdentityStr) {
    localStorage.setItem(`userIdentity-${address}`, JSON.stringify({ version }))
  }
  const cachedUserIdentity: UserIdentity | undefined =
    (cachedUserIdentityStr &&
      (() => {
        try {
          return JSON.parse(cachedUserIdentityStr)
        } catch {
          return undefined
        }
      })()) ||
    undefined
  // Fallback to Web3Auth image or default avatar for external wallets.
  const profileImage =
    cachedUserIdentity?.profileImage && cachedUserIdentity.profileImage !== avatar_01
      ? cachedUserIdentity.profileImage
      : userInfo.profileImage || avatar_01

  // Check if user signed in with Social Login or Metamask to define username
  const locallyStoredAdapter = localStorage.getItem('Web3Auth-cachedAdapter')
  const nickname =
    cachedUserIdentity?.nickname &&
    ![locallyStoredAdapter, 'metamask'].includes(cachedUserIdentity.nickname)
      ? cachedUserIdentity.nickname
      : userInfo.name || locallyStoredAdapter || 'no display name'

  // Set default username if no identity on chain
  const username = cachedUserIdentity?.username ?? ''

  // Get locally stored avatarOptions to persist across versions
  const avatarOptions = (() => {
    const avatars = cachedUserIdentity?.avatarOptions ?? avatarDefaultOptions
    const newAvatarOptions =
      avatars.length > avatarDefaultOptions.length
        ? avatars.slice(0, avatars.length - avatarDefaultOptions.length)
        : []
    return [...newAvatarOptions, ...avatarDefaultOptions]
  })()

  const snackBars: ReactNode[] = []

  const uId: UserIdentity = {
    version,
    address,
    username,
    nickname,
    profileImage,
    avatarOptions,
  }

  const savedProfile = getSavedProfileImage(uId)
  uId.profileImage = savedProfile?.profileImage ?? uId.profileImage
  uId.avatarOptions = savedProfile?.avatarOptions ?? uId.avatarOptions

  const uIdPromise = Promise.resolve(deleteUserIdentityIfDifferentVersion(uId))
    .then(async newUserIdentity => {
      // Check if user has a username
      try {
        const l1nsUsername = await getL1nsName(address)

        if (l1nsUsername && l1nsUsername !== BLANK_ADDRESS) {
          newUserIdentity.username = l1nsUsername
        }
      } catch (e: any) {
        // if not connected to ID subnet chain or error when connecting
        console.error(e)
        snackBars.push(
          <Snackbar
            value={t(
              'errors.snackbar.idSubnet.username',
              `Error fetching Username for {{address}}. Using locally stored username if any.`,
              {
                address,
              }
            )}
            title={t('errors.snackbar.idSubnet.title', 'Not connected to ID Subnet')}
            icon={<IconAlertCircle size="14px" />}
          />
        )
      }
      return newUserIdentity
    })
    .then(async newUserIdentity => {
      if (newUserIdentity.username) {
        // Check if user has a nickname
        try {
          newUserIdentity.nickname =
            (await getL1nsNickname(newUserIdentity.username)) || newUserIdentity.nickname
        } catch (e: any) {
          // if not connected to ID subnet chain or error when connecting
          snackBars.push(
            <Snackbar
              value={t(
                'errors.snackbar.idSubnet.displayName',
                `Error fetching Display Name for {{username}}. Using locally stored name if any.`,
                {
                  username: newUserIdentity.username,
                }
              )}
              title={t('errors.snackbar.idSubnet.title', 'Not connected to ID Subnet')}
              icon={<IconAlertCircle size="14px" />}
            />
          )
          console.error('USER IDENTITY ERROR:', e, newUserIdentity.username)
        }
      }
      // set userIdentity to local storage
      localStorage.setItem(`userIdentity-${address}`, JSON.stringify(newUserIdentity))
      return newUserIdentity
    })
    .finally(() => {
      if (snackBars.length) {
        closeSnackbar()
        snackBars.forEach(enqueueSnackbar)
      }
    })

  uIdPromise.catch(noop)

  return uIdPromise
}
