import { Dispatch, Reducer, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { UniformUnion } from '@/utils/types'

/* *********************************************************** */
/*                usePromiseState internal types               */
/* *********************************************************** */

// region InternalState
enum InternalStep {
  Idle,
  PromiseStarted,
  PromiseResolved,
  PromiseRejected,
}

type InternalStateInitial = {
  // No promise set
  step: InternalStep.Idle
}
type InternalStatePromiseStarted<T> = {
  step: InternalStep.PromiseStarted
  promise: Promise<T>
  listeners?: PromiseListener<T>
}
type InternalStatePromiseResolved<T> = {
  step: InternalStep.PromiseResolved
  promise: Promise<T>
  result: { resolved: T }
}
type InternalStatePromiseRejected<T> = {
  step: InternalStep.PromiseRejected
  promise: Promise<T>
  result: { rejected: any }
}

type InternalState<T> =
  | InternalStateInitial
  | InternalStatePromiseStarted<T>
  | InternalStatePromiseResolved<T>
  | InternalStatePromiseRejected<T>
// endregion

// region Actions
enum ActionType {
  ResetPromise,
  StartPromise,
  ResolvePromise,
  RejectPromise,
}

type ActionResetPromise = {
  type: ActionType.ResetPromise
}
type ActionStartPromise<T> = {
  type: ActionType.StartPromise
  promise: Promise<T>
  listeners?: PromiseListener<T>
  forceNewState: boolean
}
type ActionResolvePromise<T> = {
  type: ActionType.ResolvePromise
  promise: Promise<T>
  resolved: T
}
type ActionRejectPromise<T> = {
  type: ActionType.RejectPromise
  promise: Promise<T>
  rejected: any
}
type Action<T> =
  | ActionResetPromise
  | ActionStartPromise<T>
  | ActionResolvePromise<T>
  | ActionRejectPromise<T>
// endregion

type PromiseReducer<T> = Reducer<InternalState<T>, Action<T>>

/* *********************************************************** */
/*              usePromiseState public interface               */
/* *********************************************************** */

/**
 * This represents the current state of the promise. The state can be one of four types:
 *
 * - No promise set: Indicates that there is currently no promise being tracked.
 * - Promise started: The promise is in progress but has not yet been resolved or rejected.
 * - Promise resolved: The promise has been successfully resolved, and the resolution value is available.
 * - Promise rejected: The promise has been rejected, and the rejection reason is available.
 */
export type PromiseState<T> = UniformUnion<
  [
    /**
     * No promise set
     */
    { promise: undefined },
    /**
     * Promise started, but not resolved or rejected yet
     */
    { promise: Promise<T> },
    /**
     * Promise resolved
     */
    { promise: Promise<T>; resolved: T },
    /**
     * Promise rejected
     */
    { promise: Promise<T>; rejected: any },
  ]
>

export type PromiseListener<T> =
  | [onResolve: (resolved: T) => void, onReject?: (rejected: any) => void]
  | [onResolve: undefined, onReject: (rejected: any) => void]

export type UsePromiseState<T> = readonly [
  /**
   * This represents the current state of the promise. The state can be one of four types:
   *
   * - No promise set: Indicates that there is currently no promise being tracked.
   * - Promise started: The promise is in progress but has not yet been resolved or rejected.
   * - Promise resolved: The promise has been successfully resolved, and the resolution value is available.
   * - Promise rejected: The promise has been rejected, and the rejection reason is available.
   */
  PromiseState<T>,
  /**
   * This function allows you to set a new promise to be tracked by the hook. It takes a promise,
   * an optional listener for resolution or rejection, and an optional boolean to force a new state
   * even if another promise is already in progress.
   */
  (promise: Promise<T>, listeners?: PromiseListener<T>, forceNewState?: boolean) => void,
  /**
   * This function resets the hook so no promise is being tracked. After this function is called,
   * the promise that might have been being watched will be ignored.
   */
  () => void,
]

/**
 * A custom React hook that manages the lifecycle of a promise within a component. It tracks whether
 * the promise is idle, in progress, resolved, or rejected. The hook provides a mechanism to set a
 * new promise, handle its resolution or rejection, and reset the promise state. It's useful for
 * handling asynchronous operations in a React component in a structured and predictable manner.
 */
const usePromiseState = <T>(
  initialState?: T | Promise<T> | undefined,
  initialListeners?: PromiseListener<T> | undefined
): UsePromiseState<T> => {
  const listenToPromise = useCallback((dispatch: Dispatch<Action<T>>, promise: Promise<T>) => {
    promise.then(
      resolved => {
        dispatch({ type: ActionType.ResolvePromise, promise, resolved })
      },
      rejected => {
        dispatch({ type: ActionType.RejectPromise, promise, rejected })
      }
    )
  }, [])

  const [state, dispatch] = useReducer<
    PromiseReducer<T>,
    [initialPromise: T | Promise<T> | undefined, initialListeners: PromiseListener<T> | undefined]
  >(
    (s: InternalState<T>, a: Action<T>): InternalState<T> => {
      switch (a.type) {
        case ActionType.ResetPromise:
          return { step: InternalStep.Idle }
        case ActionType.StartPromise: {
          // If there is a promise already running...
          if (s.step === InternalStep.PromiseStarted) {
            // ...and the promise is the same ...
            if (s.promise === a.promise) {
              // ...but the listeners are different, update the listeners
              if (s.listeners !== a.listeners) {
                return { ...s, listeners: a.listeners }
              }
              // ...and the listeners are the same, do nothing
              return s
            }

            // ...and the new promise is different...

            // ...and forceNewState is false, ignore the new promise and log a warning
            if (!a.forceNewState) {
              console.warn(
                'Attempted to starting a different promise while another was was still loading. Ignoring new promise.',
                s,
                a
              )
              return s
            }

            // ...and forceNewState is true, log a warning in dev mode and continue to outside the if block
            if (process.env.NODE_ENV === 'development') {
              console.debug(
                'Starting a different promise while another was was still loading. Replacing old promise.',
                s,
                a
              )
            }
          }

          // If there's no promise pending or the new promise is different
          // and forceNewState is true, replace the old promise with the new one
          queueMicrotask(() => listenToPromise(dispatch, a.promise))

          return {
            step: InternalStep.PromiseStarted,
            promise: a.promise,
            listeners: a.listeners,
          }
        }
        case ActionType.ResolvePromise: {
          if (s.step !== InternalStep.PromiseStarted) return s
          if (a.promise !== s.promise) return s

          return {
            step: InternalStep.PromiseResolved,
            promise: a.promise,
            result: { resolved: a.resolved },
          }
        }
        case ActionType.RejectPromise:
          if (s.step !== InternalStep.PromiseStarted) return s
          if (a.promise !== s.promise) return s

          return {
            step: InternalStep.PromiseRejected,
            promise: a.promise,
            result: { rejected: a.rejected },
          }
        default:
          console.warn('Unknown promise reducer action', a)
          return s
      }
    },
    [initialState, initialListeners],
    ([iS, iL]) => {
      if (iS === undefined) return { step: InternalStep.Idle }
      // If the initial state is a promise, start right away
      if (iS instanceof Promise) {
        queueMicrotask(() => listenToPromise(dispatch, iS))
        return { step: InternalStep.PromiseStarted, promise: iS, listeners: iL }
      }

      const promise = Promise.resolve(iS)

      if (Array.isArray(iL)) {
        queueMicrotask(() => listenToPromise(dispatch, promise))
        return { step: InternalStep.PromiseStarted, promise, listeners: iL }
      }

      return {
        step: InternalStep.PromiseResolved,
        promise,
        result: { resolved: iS },
      }
    }
  )

  // Effect to run the promise listeners
  const prevEffectStateRef = useRef<InternalState<T> | undefined>()
  useEffect(() => {
    const { current: prevEffectState } = prevEffectStateRef
    prevEffectStateRef.current = state

    if (
      prevEffectState?.step !== InternalStep.PromiseStarted ||
      (prevEffectState as InternalStatePromiseStarted<T>)?.promise !==
        (state as InternalStatePromiseStarted<T>)?.promise
    )
      return

    if (state.step === InternalStep.PromiseResolved)
      prevEffectState.listeners?.[0]?.(state.result.resolved)
    else if (state.step === InternalStep.PromiseRejected)
      prevEffectState.listeners?.[1]?.(state.result.rejected)
  }, [state])

  const reset = useCallback(() => {
    dispatch({ type: ActionType.ResetPromise })
  }, [])

  const setPromise = useCallback(
    (promise: Promise<T>, listeners?: PromiseListener<T>, forceNewState: boolean = false) => {
      dispatch({ type: ActionType.StartPromise, promise, listeners, forceNewState })
    },
    []
  )

  return useMemo<UsePromiseState<T>>(() => {
    let retState: PromiseState<T>
    switch (state?.step) {
      case InternalStep.PromiseStarted:
        retState = { promise: state.promise }
        break
      case InternalStep.PromiseResolved:
        retState = {
          promise: state.promise,
          resolved: state.result.resolved,
        }
        break
      case InternalStep.PromiseRejected:
        retState = {
          promise: state.promise,
          rejected: state.result.rejected,
        }
        break
      default:
        retState = { promise: undefined }
    }

    return [retState, setPromise, reset]
  }, [state, setPromise, reset])
}

export default usePromiseState
