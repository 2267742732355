import { MantineThemeOverride } from '@mantine/styles/lib/theme/types'
import { useMemo } from 'react'
import { Theme } from '../../utils/utils'

// Define the dark theme colors
export const darkTheme: Theme = {
  background: '#000000',
  background2: '#101213',
  background3: '#202324',

  title: '#ffffff',
  textAndIcon: '#888888',
  paragraph: '#B1B1B1',

  l1Primary: '#01FE57',
  l1Secondary: '#0000FF',
  l1PrimaryOpacity: 'rgba(1, 254, 87, 0.33)',
  l1SecondaryOpacity: 'rgba(0, 0, 255, 0.5)',

  notification: '#FD406E',

  splitter: '#272727',

  profileOutline: '#2E2E2E',
  popUpOutline: '#1A1919',
}

// Define the light theme colors
export const lightTheme: Theme = {
  background: '#ffffff',
  background2: '#F9F9F9',
  background3: '#E9ECED',

  title: '#000000',
  textAndIcon: '#C2C1C1',
  paragraph: '#777777',

  l1Primary: '#0000FF',
  l1Secondary: '#01FE57',
  l1PrimaryOpacity: 'rgba(0, 0, 255, 0.2)',
  l1SecondaryOpacity: 'rgba(1, 254, 87, 0.33)',

  notification: '#FF3541',

  splitter: '#EEEEEE',

  profileOutline: '#EEEEEE',
  popUpOutline: '#F0F0F0',
}

function mapThemeToMantineTheme(customTheme: Theme): MantineThemeOverride {
  return {
    colors: {
      background: [customTheme.background],
      background2: [customTheme.background2],
      background3: [customTheme.background3],
      title: [customTheme.title],
      textAndIcon: [customTheme.textAndIcon],
      paragraph: [customTheme.paragraph],
      l1Primary: [customTheme.l1Primary],
      l1Secondary: [customTheme.l1Secondary],
      l1PrimaryOpacity: [customTheme.l1PrimaryOpacity],
      l1SecondaryOpacity: [customTheme.l1SecondaryOpacity],
      notification: [customTheme.notification],
      splitter: [customTheme.splitter],
      profileOutline: [customTheme.profileOutline],
      popUpOutline: [customTheme.popUpOutline],
    },
    fontFamily: 'monument-grotesk-regular, sans-serif',
    fontFamilyMonospace: 'Courier New, monospace',
    headings: {
      fontWeight: 700,
      fontFamily: 'monument-grotesk-heavy, sans-serif',
      sizes: {
        h1: { fontWeight: 700, fontSize: '72px', lineHeight: '66.6px' },
        h2: { fontWeight: 700, fontSize: '52px', lineHeight: '53px' },
        h3: { fontWeight: 700, fontSize: '34px', lineHeight: '35px' },
        h4: { fontWeight: 700, fontSize: '19px', lineHeight: '25px' },
        h5: { fontWeight: 700, fontSize: '14px', lineHeight: '16px' },
        h6: { fontWeight: 700, fontSize: '12px', lineHeight: '14px' },
      },
    },
    breakpoints: {
      xs: '450px',
      sm: '576px',
      md: '768px',
      lg: '1270px',
      xl: '1500px',
    },
    fontSizes: {
      xs: '0.6rem',
      sm: '0.75rem',
      md: '0.9rem',
      lg: '1rem',
      xl: '1.2rem',
    },
    components: {
      Button: {
        defaultProps: {
          radius: '12px',
        },
        styles: () => ({
          root: {
            fontFamily: 'monument-grotesk-bold, sans-serif',
            backgroundColor: customTheme.background,
            borderColor: customTheme.title,
            color: customTheme.title,
            '&:hover': {
              backgroundColor: customTheme.l1Primary,
              borderColor: customTheme.l1Primary,
              color: customTheme.background,
            },
          },
          icon: {
            width: 18,
            height: 18,
          },
        }),
      },
      Container: {
        defaultProps: {
          sizes: {
            xs: 540,
            sm: 720,
            md: 960,
            lg: 1140,
            xl: 1320,
          },
        },
      },
      Title: {
        defaultProps: {
          order: 3,
        },
        styles: () => ({
          root: {
            textAlign: 'left',
            fontFamily: 'monument-grotesk-heavy, sans-serif',
            fontWeight: 700,
            color: customTheme.title,
          },
        }),
      },
      Text: {
        defaultProps: {
          color: customTheme.paragraph,
        },
        styles: () => ({
          root: {
            textAlign: 'left',
          },
        }),
      },
      Tooltip: {
        defaultProps: {
          events: {
            touch: true,
            focus: true,
            hover: true,
          },
          withArrow: true,
          multiline: true,
          position: 'top',
          offset: 10,
          sx: {
            maxWidth: 260,
          },
        },
      },
      Modal: {
        defaultProps: {
          radius: '12px',
        },
        styles: () => ({
          header: {
            backgroundColor: customTheme.background2,
            fontWeight: 700,
            color: customTheme.title,
          },
          title: {
            fontSize: '19px',
            fontFamily: 'monument-grotesk-heavy, sans-serif',
            fontWeight: 700,
          },
          content: {
            border: `2px solid ${customTheme.popUpOutline}`,
            backgroundColor: customTheme.background2,
            color: customTheme.title,
            padding: '12px',
            borderRadius: '12px',
            margin: 'auto',
            '&:focus': {
              outline: 'none',
            },
            maxWidth: '96%',
          },
          overlay: {
            zIndex: 0,
          },
          inner: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          close: {
            backgroundColor: customTheme.background2,
            color: customTheme.title,
            '&:hover': {
              backgroundColor: customTheme.l1Primary,
              borderColor: customTheme.l1Primary,
              color: customTheme.background2,
            },
          },
        }),
      },
      Slider: {
        defaultProps: {
          radius: '12px',
          size: 2,
        },
        styles: () => ({
          root: {
            '&:focus': {
              outline: 'none !important',
            },
          },
          track: {
            '&::before': {
              backgroundColor: customTheme.background3,
            },
          },
          thumb: {
            height: '15px',
            width: '15px',
            backgroundColor: customTheme.background,
            borderColor: customTheme.l1Primary,
            borderWidth: '5px',
            '&:active ': {
              backgroundColor: customTheme.l1PrimaryOpacity,
              height: '25px',
              width: '25px',
              borderColor: customTheme.l1PrimaryOpacity,
              borderWidth: '8px',
            },
            '&:hover': {
              height: '25px',
              width: '25px',
              backgroundColor: customTheme.l1PrimaryOpacity,
              borderColor: customTheme.l1PrimaryOpacity,
              borderWidth: '8px',
            },
          },
          bar: {
            backgroundColor: customTheme.l1Primary,
          },
        }),
      },
      RangeSlider: {
        defaultProps: {
          radius: '12px',
          size: 2,
        },
        styles: () => ({
          root: {
            '&:focus': {
              outline: 'none !important',
            },
          },
          track: {
            '&::before': {
              backgroundColor: customTheme.background3,
            },
          },
          thumb: {
            height: '15px',
            width: '15px',
            backgroundColor: customTheme.background,
            borderColor: customTheme.l1Primary,
            borderWidth: '5px',
            '&:active ': {
              backgroundColor: customTheme.l1PrimaryOpacity,
              height: '25px',
              width: '25px',
              borderColor: customTheme.l1PrimaryOpacity,
              borderWidth: '8px',
            },
            '&:hover': {
              height: '25px',
              width: '25px',
              backgroundColor: customTheme.l1PrimaryOpacity,
              borderColor: customTheme.l1PrimaryOpacity,
              borderWidth: '8px',
            },
          },
          bar: {
            backgroundColor: customTheme.l1Primary,
          },
        }),
      },
      TextInput: {
        defaultProps: {
          radius: '12px',
        },
        styles: () => ({
          input: {
            backgroundColor: customTheme.background,
            color: customTheme.title,
            fontSize: 16,
          },
        }),
      },
      Pagination: {
        defaultProps: {
          radius: '12px',
          color: customTheme.l1Primary,
        },
        styles: () => ({
          control: {
            fontFamily: 'monument-grotesk-bold, sans-serif',
            fontWeight: 700,
            backgroundColor: customTheme.background,
            color: customTheme.title,
            border: 'none',
            '&:hover': {
              backgroundColor: customTheme.l1Primary,
              color: customTheme.background,
            },
            '&:not([data-disabled]):hover': {
              backgroundColor: customTheme.l1Primary,
              color: customTheme.background,
            },
            '&[data-active]': {
              backgroundColor: customTheme.title,
              color: customTheme.background,
            },
            '&[data-active]:hover': {
              backgroundColor: `${customTheme.l1Primary} !important`,
              color: customTheme.background,
            },
          },
          dots: {},
        }),
      },
      ActionIcon: {
        defaultProps: {
          color: customTheme.l1Primary,
        },
        styles: () => ({
          root: {
            // backgroundColor: customTheme.background,
            color: customTheme.title,
            '&:hover': {
              backgroundColor: customTheme.l1Primary,
              color: customTheme.background,
            },
          },
        }),
      },
      Select: {
        styles: () => ({
          root: {
            borderRadius: '12px',
            backgroundColor: customTheme.background3,
            color: customTheme.title,
            '&:hover': {
              backgroundColor: customTheme.l1Primary,
              color: customTheme.background,
            },
          },
          input: {
            backgroundColor: customTheme.background3,
            color: customTheme.title,
            '&:hover': {
              backgroundColor: customTheme.l1Primary,
              color: customTheme.background,
            },
          },
          item: {
            '&[data-selected]': {
              '&, &:hover': {
                backgroundColor: customTheme.l1Primary,
                color: customTheme.background,
              },
            },
          },
        }),
      },
      Radio: {
        styles: () => ({
          radio: {
            backgroundColor: customTheme.background3,
            color: customTheme.title,
            border: `2px solid ${customTheme.textAndIcon}`,
            '&:hover': {
              backgroundColor: customTheme.l1Primary,
              color: customTheme.background,
            },
          },
          input: {
            '&:checked': {
              backgroundColor: customTheme.l1Primary,
              color: customTheme.background,
            },
          },
          label: {
            color: customTheme.paragraph,
            fontFamily: 'monument-grotesk, sans-serif',
            fontSize: '14px',
          },
          inner: {
            input: {
              '&:checked': {
                backgroundColor: customTheme.background,
                borderColor: customTheme.title,
              },
            },
          },
          icon: {
            color: customTheme.l1Primary,
            borderColor: customTheme.l1Primary,
            borderWidth: '20px',
          },
        }),
      },
      RadioGroup: {
        styles: () => ({
          label: {
            color: customTheme.title,
            fontFamily: 'monument-grotesk-heavy, sans-serif',
            fontSize: '18px',
          },
        }),
      },
      Divider: {
        defaultProps: {
          color: customTheme.splitter,
          height: 1,
        },
      },
      Stepper: {
        styles: () => ({
          root: {
            width: '100%',
          },
          stepLabel: {
            fontFamily: 'monument-grotesk-heavy, sans-serif',
            color: customTheme.title,
          },
          stepBody: {
            marginLeft: '5px',
          },

          stepCompletedIcon: {
            color: customTheme.background,
          },
          stepIcon: {
            border: `2px solid ${customTheme.title}`,
            backgroundColor: customTheme.background,
            color: customTheme.background,
          },
          separator: {
            marginLeft: '5px',
            marginRight: '5px',
          },
        }),
      },
      Switch: {
        styles: () => ({
          thumb: {
            backgroundColor: customTheme.background,
          },
          track: {
            backgroundColor: customTheme.notification,
            border: `2px solid ${customTheme.notification}`,
          },
        }),
      },
      Checkbox: {
        styles: () => ({
          label: {
            color: customTheme.title,
            fontFamily: 'monument-grotesk, sans-serif',
            fontSize: '15px',
            paddingLeft: '8px',
          },
          icon: {
            color: `${customTheme.background3} !important`,
          },
          input: {
            '&:checked': {
              backgroundColor: customTheme.l1Primary,
              borderColor: customTheme.profileOutline,
            },
            backgroundColor: customTheme.background3,
            borderColor: customTheme.profileOutline,
          },
        }),
      },
    },
  }
}

export function useCustomTheme(light: boolean): {
  activeTheme: Theme
  mantineTheme: MantineThemeOverride
} {
  const activeTheme = light ? lightTheme : darkTheme
  return useMemo(
    () => ({
      activeTheme,
      mantineTheme: mapThemeToMantineTheme(activeTheme),
    }),
    [activeTheme]
  )
}
