import { type Dispatch, useEffect } from 'react'
import { type AuthAction, AuthActionType } from '@/plugins/auth/AuthProvider/types/AuthActions'

// Handle closing the connection modal
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export default function _useOnModalClose(dispatch: Dispatch<AuthAction>) {
  useEffect(() => {
    const modalSelector = 'section#w3a-parent-container > section#w3a-container'
    const web3authModalNode = document.querySelector(modalSelector)
    if (!web3authModalNode) {
      console.error(
        `The auth modal (${modalSelector}) wasn't found in the DOM. Did the library change?`
      )
      return undefined
    }

    const mut = new MutationObserver(() => {
      const isWeb3AuthModalOpen = !!web3authModalNode.children?.length
      if (!isWeb3AuthModalOpen) {
        dispatch({
          type: AuthActionType.ConnectCancel,
        })
      }
    })
    mut.observe(web3authModalNode, {
      childList: true,

      subtree: false,
      attributes: false,
      attributeFilter: undefined,
      characterData: false,
      characterDataOldValue: false,
      attributeOldValue: false,
    })
    return () => {
      mut.disconnect()
    }
  }, [dispatch])
}
