import React, { useMemo, useState } from 'react'
import { useMantineTheme } from '@mantine/core'
import Loading from '../Loading'

interface IconWrapperProps extends React.ComponentPropsWithoutRef<'img'> {
  classes?: any
  iconDark: string
  iconLight: string
  loader?: boolean
  imageLoaderHeight?: string
  noText?: boolean
  size?: string
}
const IconWrapper: React.FC<IconWrapperProps> = ({
  classes,
  iconDark,
  iconLight,
  loader = false,
  imageLoaderHeight = '100%',
  noText = false,
  size = '100%',
  ...props
}) => {
  const theme = useMantineTheme()
  const { style, ...rest } = props
  const [isLoading, setIsLoading] = useState(true)

  const icon = useMemo(
    () => (theme.colors.background[0] === '#000000' ? iconDark : iconLight),
    [theme.colors.background[0]]
  )

  const handleLoading = () => {
    if (loader) {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && loader && (
        <Loading noText={noText} size={size} imageLoaderHeight={imageLoaderHeight} />
      )}
      <img
        className={classes}
        {...rest}
        style={{ display: isLoading && loader ? 'none' : 'initial', ...style }}
        src={icon}
        alt={icon}
        onLoad={handleLoading}
      />
    </>
  )
}

export default IconWrapper
