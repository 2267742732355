import type { ConnectResult, PublicClient } from '@wagmi/core'
import type { OpenloginUserInfo } from '@web3auth/openlogin-adapter'
import { Dispatch, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { useIdSubnet } from '@/hooks/useViemProvider'
import { getUserIdentityPromise } from '@/plugins/auth/AuthProvider/effects/functions'
import { getWeb3AuthConnector, getWeb3AuthInstance } from '@/plugins/auth/config'
import { withTimeout } from '@/utils/helpers'
import usePromiseState from '@/hooks/usePromiseState'
import {
  AuthSteps,
  InternalAuthState,
  throwIfInvalidStateTransition,
} from '@/plugins/auth/AuthProvider/types/AuthInternalState'
import { L1Provider } from '@/plugins/auth/AuthProvider/types/context'
import { UserIdentity } from '@/utils/utils'
import { AuthAction, AuthActionType } from '../types/AuthActions'

const LOGIN_STEP_TIMEOUT_MS = 10_000 // 10s
const LOGOUT_TIMEOUT_MS = 2_000 // 2s

function usePromiseStates() {
  // DisconnectInit promises managers
  const [, setWagmiDisconnectPromiseState, resetWagmiDisconnectPromiseState] =
    usePromiseState<void>()
  const [, setWeb3AuthDisconnectPromiseState, resetWeb3AuthDisconnectPromiseState] =
    usePromiseState<void>()

  // Connect promises managers
  const [, setWagmiConnectPromise, resetWagmiConnectPromiseState] =
    usePromiseState<ConnectResult<PublicClient>>()
  const [, setUserInfoPromise, resetUserInfoPromiseState] =
    usePromiseState<Partial<OpenloginUserInfo>>()
  const [, setUserIdentityPromise, resetUserIdentityPromiseState] = usePromiseState<UserIdentity>()
  const [, setProviderPromise, resetProviderPromiseState] = usePromiseState<L1Provider>()
  const resetPromises = useCallback(() => {
    resetWagmiConnectPromiseState()
    resetUserInfoPromiseState()
    resetUserIdentityPromiseState()
    resetProviderPromiseState()

    resetWagmiDisconnectPromiseState()
    resetWeb3AuthDisconnectPromiseState()
  }, [
    resetWagmiConnectPromiseState,
    resetUserInfoPromiseState,
    resetUserIdentityPromiseState,
    resetProviderPromiseState,
    resetWagmiDisconnectPromiseState,
    resetWeb3AuthDisconnectPromiseState,
  ])

  return useMemo(
    () =>
      ({
        setWagmiDisconnectPromiseState,
        resetWagmiDisconnectPromiseState,
        setWeb3AuthDisconnectPromiseState,
        resetWeb3AuthDisconnectPromiseState,

        setWagmiConnectPromise,
        resetWagmiConnectPromiseState,
        setUserInfoPromise,
        resetUserInfoPromiseState,
        setUserIdentityPromise,
        resetUserIdentityPromiseState,
        setProviderPromise,
        resetProviderPromiseState,

        resetPromises,
      }) as const,
    [
      setWagmiDisconnectPromiseState,
      resetWagmiDisconnectPromiseState,
      setWeb3AuthDisconnectPromiseState,
      resetWeb3AuthDisconnectPromiseState,
      setWagmiConnectPromise,
      resetWagmiConnectPromiseState,
      setUserInfoPromise,
      resetUserInfoPromiseState,
      setUserIdentityPromise,
      resetUserIdentityPromiseState,
      setProviderPromise,
      resetProviderPromiseState,
      resetPromises,
    ]
  )
}

// Effect for triggering the async actions on state transitions
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export default function _useStateTransitions(
  internalState: InternalAuthState,
  dispatch: Dispatch<AuthAction>
) {
  const promiseStates = usePromiseStates()

  const { t } = useTranslation()
  const wagmiAccount = useAccount()
  const { connectAsync } = useConnect()
  const { disconnectAsync } = useDisconnect()

  const { getL1nsName, getL1nsNickname } = useIdSubnet()

  const prevInternalStateUseEffectRef = useRef<InternalAuthState | undefined>()
  useEffect(() => {
    const { current: prevInternalState } = prevInternalStateUseEffectRef
    prevInternalStateUseEffectRef.current = internalState

    if (prevInternalState !== internalState) {
      console.log('New state:', internalState)
    }

    if (!prevInternalState) {
      if (internalState.step !== AuthSteps.Init) {
        console.log('Invalid initial state:', internalState)
      }
      // Don't return here to allow the next if block to execute
    }

    if (internalState.step === AuthSteps.Init) {
      // useSyncWithWagmi should handle this case
      return
    }

    // If this is the first render and the initial wagmi state is not yet final
    // (otherwise the previous if block would have returned), do nothing and
    // wait for it to change.
    if (!prevInternalState) return

    throwIfInvalidStateTransition(prevInternalState, internalState)

    // If the step hasn't changed, this effect is running due to another dependency changing,
    // so we don't need to do anything.
    if (prevInternalState.step === internalState.step) return

    switch (internalState.step) {
      case AuthSteps.DisconnectingInit:
        promiseStates.resetPromises()
        dispatch({
          type: AuthActionType.DisconnectWagmi,
        })
        break
      case AuthSteps.DisconnectingWagmi:
        promiseStates.setWagmiDisconnectPromiseState(
          withTimeout(disconnectAsync(), LOGOUT_TIMEOUT_MS),
          [
            () => {
              dispatch({
                type: AuthActionType.DisconnectStartWeb3AuthLogout,
              })
            },
            e => {
              // Log but ignore
              if (process.env.NODE_ENV === 'development') console.warn(e)
              dispatch({
                type: AuthActionType.DisconnectStartWeb3AuthLogout,
              })
            },
          ]
        )
        break
      case AuthSteps.DisconnectingLogoutWeb3Auth:
        promiseStates.setWeb3AuthDisconnectPromiseState(
          withTimeout(getWeb3AuthInstance().logout(), LOGOUT_TIMEOUT_MS),
          [
            () => {
              dispatch({
                type: AuthActionType.Disconnected,
              })
            },
            e => {
              // Log but ignore
              if (process.env.NODE_ENV === 'development') console.warn(e)
              dispatch({
                type: AuthActionType.Disconnected,
              })
            },
          ]
        )
        break
      case AuthSteps.Disconnected:
        promiseStates.resetPromises()
        break

      case AuthSteps.ConnectingInit:
        promiseStates.resetPromises()
        dispatch({
          type: AuthActionType.ConnectWagmi,
        })
        break
      case AuthSteps.ConnectingWagmi:
        promiseStates.setWagmiConnectPromise(connectAsync({ connector: getWeb3AuthConnector() }), [
          res => {
            dispatch({
              type: AuthActionType.ConnectUserInfo,
              address: res.account,
            })
          },
          e => {
            promiseStates.resetPromises()
            dispatch({
              type: AuthActionType.ConnectError,
              error: e,
            })
          },
        ])
        break
      case AuthSteps.ConnectingUserInfo:
        promiseStates.setUserInfoPromise(
          withTimeout(getWeb3AuthInstance().getUserInfo(), LOGIN_STEP_TIMEOUT_MS),
          [
            res => {
              dispatch({
                type: AuthActionType.ConnectUserIdentity,
                userInfo: res,
              })
            },
            e => {
              promiseStates.resetPromises()
              dispatch({
                type: AuthActionType.ConnectError,
                error: e,
              })
            },
          ]
        )
        break
      case AuthSteps.ConnectingUserIdentity:
        promiseStates.setUserIdentityPromise(
          withTimeout(
            getUserIdentityPromise(internalState, getL1nsName, getL1nsNickname, t),
            LOGIN_STEP_TIMEOUT_MS
          ),
          [
            res => {
              dispatch({
                type: AuthActionType.ConnectProvider,
                userIdentity: res,
              })
            },
            e => {
              promiseStates.resetPromises()
              dispatch({
                type: AuthActionType.ConnectError,
                error: e,
              })
            },
          ]
        )
        break
      case AuthSteps.ConnectingProvider:
        promiseStates.setProviderPromise(
          withTimeout(getWeb3AuthConnector().getProvider(), LOGIN_STEP_TIMEOUT_MS),
          [
            res => {
              dispatch({
                type: AuthActionType.Connected,
                provider: res,
              })
            },
            e => {
              promiseStates.resetPromises()
              dispatch({
                type: AuthActionType.ConnectError,
                error: e,
              })
            },
          ]
        )
        break
      case AuthSteps.Connected:
        promiseStates.resetPromises()
        break
      default:
        if (process.env.NODE_ENV === 'development')
          console.warn('Unhandled step:', internalState.step)
        break
    }
  }, [
    connectAsync,
    disconnectAsync,
    dispatch,
    getL1nsName,
    getL1nsNickname,
    internalState,
    promiseStates,
    t,
    wagmiAccount.address,
    wagmiAccount.status,
  ])
}
