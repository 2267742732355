import React from 'react'
import { Stack, Title, Text, createStyles } from '@mantine/core'

const useStyles = createStyles(theme => ({
  copyMessageModal: {
    maxHeight: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    gap: '1em',
    flexDirection: 'row',
  },
  copyMessageHeader: {
    maxWidth: '53vw',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: '0.25em',
  },
  copyMessageTitle: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    margin: 'auto 0',
  },
  copyMessageIcon: {
    color: theme.colors.l1Primary[0],
  },
  copyMessageValue: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    color: theme.colors.paragraph[0],
    margin: 'auto 0',
    overflowWrap: 'anywhere',
    textWrap: 'wrap',
  },
  rightElement: {
    justifyContent: 'center',
    margin: 'auto 0',
    padding: '0',
  },
}))

interface SnackbarProps {
  value: string | JSX.Element
  title: string
  icon: JSX.Element
  rightElement?: JSX.Element
}

const Snackbar: React.FC<SnackbarProps> = ({ value, title, icon, rightElement = null }) => {
  const { classes } = useStyles()
  return (
    <Stack className={classes.copyMessageModal}>
      {icon}
      <Stack className={classes.copyMessageHeader}>
        <Title className={classes.copyMessageTitle}>{title}</Title>
        <Text className={classes.copyMessageValue}>{value}</Text>
      </Stack>
      <Stack className={classes.rightElement}>{rightElement}</Stack>
    </Stack>
  )
}

export default Snackbar
